import { Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import DataContext from '../Context/DataContext'

export const Fullvision = () => {

    const { todo, setCss } = useContext(DataContext)

    const loc = useLocation()

    useEffect(() => {
        setCss(loc.pathname, window.innerWidth)


        window.scrollTo(0, 0)

    }, [])

    return (
        <>

            <Grid container className='pad' id='fullvision'>

                <Grid item id='fullTitle'>
                    Fullvision
                </Grid>

                <Grid container item columns={{ xs: 1, md: 2, lg: 2 }} direction='row'>
                    <Grid container direction={'column'} xs={12} md={3} lg={3} id='first'>
                        <Grid container xs={12} md={2} lg={2}>
                            Tecnología
                        </Grid>
                        <Grid container xs={12} md={10} lg={10}>
                            Con Fullvision tenemos las herramientas de tecnología para ofrecer soluciones para
                            aumentar la eficiencia
                        </Grid>
                    </Grid>

                    <Grid container xs={12} md={2} lg={9} >
                        <Grid container>
                            <video autoPlay controls muted loop>
                                <source src="videos/fullvision_v2.mp4" type="video/mp4" />
                            </video>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item columns={{ xs: 1, md: 3, lg: 3 }} direction='row' spacing={{ xs: 5, md: 10, lg: 10 }} id='bloque2Full'>

                    <Grid item direction={'column'} xs={12} md={1} lg={1}>
                        <Grid item id='firstFull'>
                            Inteligencia artificial
                        </Grid>
                        <Grid item>
                            Respuesta automática de voz
                            <br />
                            Reconocimiento de voz
                            <br />
                            Reconocimiento de rostros
                            <br />
                            Analítica de video
                        </Grid>
                    </Grid>

                    <Grid item direction={'column'} xs={12} md={1} lg={1}>
                        <Grid item id='firstFull'>
                            Localización geográfica
                        </Grid>
                        <Grid item>
                            Localización satelital
                            <br />
                            Triangulación de móviles
                            <br />
                            Análisis de rutas
                        </Grid>
                    </Grid>

                    <Grid item direction={'column'} xs={12} md={1} lg={1}>
                        <Grid item id='firstFull'>
                            Nube
                        </Grid>
                        <Grid item>
                            Virtualización de servidores
                            <br />
                            Almacenamiento de datos
                            <br />
                            Streaming
                            <br />
                            Big data
                        </Grid>
                    </Grid>

                    <Grid item direction={'column'} xs={12} md={1} lg={1}>
                        <Grid item id='firstFull'>
                            Radio frecuencia
                        </Grid>
                        <Grid item>
                            Sistemas celulares
                            <br />
                            Antenas
                            <br />
                            Repetidores
                            <br />
                            Análisis de propagación
                        </Grid>
                    </Grid>

                    <Grid item direction={'column'} xs={12} md={1} lg={1}>
                        <Grid item id='firstFull'>
                            Internet de las cosas
                        </Grid>
                        <Grid item>
                            Temperatura, posición,
                            <br />
                            aceleración,
                            <br />
                            localización y control
                        </Grid>
                    </Grid>

                    <Grid item direction={'column'} xs={12} md={1} lg={1}>
                        <Grid item id='firstFull'>
                            Voz sobre IP
                        </Grid>
                        <Grid item>
                            Interconexión
                            <br />
                            IVR
                            <br />
                            Call center
                            <br />
                            Facturación
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

        </>
    )
}
