import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import DataContext from '../../Context/DataContext'
import { Filtrohis } from '../filtrohis'

export const HistoriasIndex = () => {

    const nav = useNavigate()

    const [icon, setIcon] = useState("images/iconoVacio.png")
    const [icon2, setIcon2] = useState("images/iconoVacio.png")
    const [icon3, setIcon3] = useState("images/iconoVacio.png")


    const loc = useLocation()

    const handleIcon = (e) => {

        switch (e.target.id) {
            case 'icon1':
                nav('operaciones')
                setIcon('images/iconoRelleno.png')
                setIcon2('images/iconoVacio.png')
                setIcon3('images/iconoVacio.png')
                break;

            case 'icon2':
                nav('comunicaciones')
                setIcon('images/iconoVacio.png')
                setIcon2('images/iconoRelleno.png')
                setIcon3('images/iconoVacio.png')
                break;

            case 'icon3':
                nav('ciudadsegura')
                setIcon('images/iconoVacio.png')
                setIcon2('images/iconoVacio.png')
                setIcon3('images/iconoRelleno.png')
                break;
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {

        switch (loc.state) {
            case 'historia':
                document.getElementById('1').classList.remove('cambioColor')
                document.getElementById('2').classList.remove('cambioColor')
                document.getElementById('3').classList.remove('cambioColor')
                break;

            case 'operaciones':
                document.getElementById('1').classList.add('cambioColor')
                document.getElementById('2').classList.remove('cambioColor')
                document.getElementById('3').classList.remove('cambioColor')
                break;

            case 'comunicaciones':
                document.getElementById('1').classList.remove('cambioColor')
                document.getElementById('2').classList.add('cambioColor')
                document.getElementById('3').classList.remove('cambioColor')
                break;

            case 'ciudadsegura':
                document.getElementById('1').classList.remove('cambioColor')
                document.getElementById('2').classList.remove('cambioColor')
                document.getElementById('3').classList.add('cambioColor')
                break;

            default:
                break;
        }



    }, [loc.state])


    return (
        <>

            <Grid container id='historia' columns={{ xs: 1, md: 12 }} direction='row' >

                <Grid container xs={11}  id='history' direction={'column'} >
                    <Link to={'/historia'} state={'historia'} id='indexH'>
                        Nuestras Historias
                    </Link>
                </Grid>

                <Grid container xs={1} id={'filtroH-icon'}  style={{paddingTop:'15px'}}>
                    <Filtrohis />
                </Grid>

                <Grid container>
                    <img src="images/divisionHorizontal.png" id='division' alt='divH' />
                </Grid>

                {/*
                 
                <Grid item xs={4} md={4} container direction={'column'} id='opciones'>
                    <Grid item xs md><br /></Grid>
                    <Grid item xs md onClick={handleIcon}>
                        <li id='icon1' >
                            <img src={icon} id='icon1' />
                            Operaciones de campo
                        </li>
                    </Grid>
                    <Grid item xs md onClick={handleIcon} >
                        <li id='icon2'>
                            <img src={icon2} id='icon2' />
                            Comunicaciones
                        </li>

                    </Grid>
                    <Grid item xs md onClick={handleIcon} >
                        <li id='icon3'>
                            <img src={icon3} id='icon3' />
                            Ciudad segura
                        </li>
                    </Grid>
                </Grid>
                */}


                <Grid container xs={12} md={5} lg={5} direction={'row'} rowSpacing={{ xs: 2 }} >
                    <Grid container xs={12} md={12} lg={6} columns={{ md: 2 }} direction={'row'} >
                        <Grid container xs={0.5} id='imgDivHis'>
                            <img src='images/divisorV.png' id='divV' />
                        </Grid>
                        <Grid container direction={'column'} xs md={11} rowSpacing={{ xs: 3, md: 5, lg: 3 }} id='opcionesHis'>
                            <Grid item xs={2} id='opcionesHis'>
                                <Link to={'operaciones'} state={'operaciones'} id='1' className='opcionesHis'>
                                    Operaciones de campo
                                </Link>
                            </Grid>
                            <Grid item xs={2} id='opcionesHis'>
                                <Link to={'comunicaciones'} state={'comunicaciones'} id='2' className='opcionesHis'>
                                    Comunicaciones
                                </Link>
                            </Grid>
                            <Grid item xs={2} id='opcionesHis'>
                                <Link to={'ciudadsegura'} state={'ciudadsegura'} id='3' className='opcionesHis'>
                                    Ciudad segura
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </>
    )
}
