import { Grid } from '@mui/material'
import React, { useEffect } from 'react'

export const Recoleccion = () => {

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    {/*Agregar identación*/ }

    {/*Todos mismo tamaño de letra*/ }

    {/*Debajo de resultados poner un separador*/ }

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Gestión de recolección de basuras
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Operaciones de campo
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container id='divAntena'>
                <img src='images/basuras.jpg' id='antena' />
            </Grid>

            <Grid container columns={{ md: 3 }} direction='row' id='row1Text'>
                <Grid container md={7} direction='column'>
                    <Grid item md={2} id='titleRow1'>
                        Cliente
                    </Grid>
                    <Grid item md={4} id='subtitleRow1'>
                        Servi Aseo, Aseo Ya
                    </Grid>
                </Grid>
                <Grid container md={5} id='textRow1-t2'>
                    El sistema de Gerencia Satelital de flotas de vehículos GeSat® permite llevar un control efectivo del cumplimiento de las rutas de recolección,
                    monitorear los indicadores de cumplimiento, recibir alertas de retrasos o incumplimientos e identificar oportunidades para hacer mas eficiente la operación.
                    De otro lado permite controlar el consumo de combustible y los mantenimientos preventivos y correctivos de la flota de vehículos.
                </Grid>
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row2Text-t'>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3-t'>
                        Contexto
                    </Grid>
                    <Grid item md={8.95} id='textRow3-t' >
                        La empresa de recolección de aseo necesita controlar que efectivamente se hagan las rutas programadas para los vehículos de recolección de basuras en los
                        horarios establecidos y adicionalmente establecer indicadores de cumplimiento para ser presentados a la alcaldía y a la comunidad.
                    </Grid>
                </Grid>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3' >
                        Solución
                    </Grid>

                    <Grid item id='textRow3-t'>
                        Se instaló en cada vehículo de recolección de basuras, los rastreadores satelitales que informan no solo la ubicación exacta de cada vehículo, sino el uso
                        de los dispositivos de compactación, peso, etc.  que reporta permanentemente
                        y el sistema central vigila de manera automática que se cumpla la programación de la operación emitiendo alertas de retraso, de retardo, de bajo rendimiento,
                        etc.
                    </Grid>
                </Grid>

            </Grid>

            <Grid container columns={{ md: 1 }} id='row3Text' rowSpacing={{ md: 2 }}>
                <Grid item id='titleRow3'>
                    Resultados
                </Grid>
                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
                <Grid item id='textRow3'>
                    Al tener la visión total de la flota y su operación se pudo  llegar a mantener cumplimientos superiores al 90% de las rutas, construyendo rutas mas eficientes y
                    con los vehículos adecuados, dejando tiempo para hacer los mantenimientos preventivos de los vehículos y cubriendo las emergencias con eficiencia.
                </Grid>
                <Grid item id='textRow3'>
                    Presentar a la alcaldía y la comunidad indicadores diarios, mensuales y anuales del servicio.  Por otra parte, se logró controlar el excesivo consumo de combustible,
                    de llantas y de otros repuestos.
                </Grid>
            </Grid>
        </>
    )
}
