import { Grid } from '@mui/material'
import React from 'react'
import { Route, Routes } from 'react-router'
import { CiudadSegura } from '../components/miniComponents/ciudadSegura'
import { Comunicaciones } from '../components/miniComponents/comunicaciones'
import { DetalleHistoria } from '../components/miniComponents/detalleHistoria'
import { HistoriasIndex } from '../components/miniComponents/historiasIndex'
import { OpercacionesCampo } from '../components/miniComponents/opercacionesCampo'

export const RouteMiniHis = () => {
    return (
        <Routes>
            <Route index element={
                <Grid container id='containerHis' columns={{ xs: 1, md: 9, lg: 9 }} rowSpacing={5} justifyContent={'center'} >
                    <OpercacionesCampo />
                    <Comunicaciones />
                    <CiudadSegura />
                </Grid>
            } />
          
            <Route path='operaciones' element={
                <Grid container direction='row' spacing={{ xs: 2, md: 3, lg: 3 }} columns={{ xs: 1, md: 9, lg: 9 }} rowSpacing={{ xs: 2, md: 5, lg: 5 }}  justifyContent={'center'} >
                    <OpercacionesCampo />
                </Grid>

            } />
            <Route path='comunicaciones' element={
                <Grid container direction='row' spacing={{ xs: 2, md: 3, lg: 3 }} columns={{ xs: 2, md: 9, lg: 9 }} rowSpacing={{ xs: 2, md: 5, lg: 5 }} id='containerHis' justifyContent={'center'}>
                    <Comunicaciones />
                </Grid>
            } />
            <Route path='ciudadsegura' element={
                <Grid container direction='row' spacing={{ xs: 2, md: 3, lg: 3 }} columns={{ xs: 2, md: 9, lg: 9 }} rowSpacing={{ xs: 2, md: 5, lg: 5 }} id='containerHis' justifyContent={'center'}>
                    <CiudadSegura />
                </Grid>
            } />
            
        </Routes>
    )
}
