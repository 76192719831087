import { Grid } from '@mui/material'
import React, { useEffect } from 'react'

export const DetalleGSat = () => {


    useEffect(() => {

        //window.scrollTo(0, 0)

    }, [])

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Gestión satelital de flotas de vehículos G-Sat
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Operaciones de campo
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container md={6} id='divAntenaGS' >
                <Grid container md={12} justifyContent='center'>
                    <Grid item md={8}>
                        <img src='images/globalCity.png' id='antenaGs' />
                    </Grid>
                </Grid>
            </Grid>


            <Grid container columns={{ md: 12 }} direction='row' id='row2Text'>

                <Grid container md={4} columnSpacing={{ md: 6 }}>
                    <Grid item id='titleRow2'>
                        Medición precisa del flujo
                    </Grid>

                    <Grid item id='textRow2'>
                        Para motores de gran consumo.
                        <br />
                        medición precisa del paso de combustible al motor
                    </Grid>
                </Grid>

                <Grid container md={4} columnSpacing={{ md: 6 }}>
                    <Grid item id='titleRow2'>
                        Medición indirecta
                    </Grid>

                    <Grid item id='textRow2'>
                        Sin necesidad de dispositivos adicionales
                        <br />
                        cálculo de consumo a través de un algoritmo
                    </Grid>
                </Grid>

                <Grid container md={4} >
                    <Grid item id='titleRow2'>
                        Medición electrónica de nivel de combustible
                    </Grid>

                    <Grid item id='textRow2'>
                        A través de un dispositivo en el tanque se lee constantemente el nivel
                        de combustible
                    </Grid>
                </Grid>

            </Grid>

        </>
    )
}
