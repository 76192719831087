import React from 'react'
import { Route, Routes } from 'react-router'
import { NoticiasIndex } from '../components/miniComponents/noticiasIndex'

export const RouteMiniNet = () => {
    return (
        <>
            <Routes>
                <Route index element={<NoticiasIndex/>} />
            </Routes>

        </>
    )
}
