import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'

export const MiniHistorias = () => {

    const nav = useNavigate()

    return (
        <>

            <Grid container justifyContent='center' xs={12} lg={4} paddingBottom={5} >
                <Card
                    sx={{ maxWidth: 300 }}
                >
                    <CardMedia
                        sx={{ height: 200 }}
                        image='images/antena.png'
                        title='cel'
                    />
                    <CardContent className='espa'>
                        <Typography gutterBottom variant="h5" component="div">
                            Bloqueo de señales inalámbricas en prisiones
                        </Typography>
                        <Typography component={'div'} variant='subtitle1'>
                            Ciudad segura
                        </Typography>
                        <Typography variant="body2" color="text.secondary" id='cardminhistoria' >
                            Sistema electrónico que impide el uso de celulares dentro
                            de las prisiones, bloqueando las señales inalámbricas
                        </Typography>
                    </CardContent>

                    <CardActions className={'portButCl'}>
                        <Button onClick={() => { nav('/historia/detalleHis') }}>Más información</Button>
                    </CardActions>
                </Card>


            </Grid>

            <Grid container justifyContent='center' xs={12} lg={4} id='cardminhistori' paddingBottom={5}>
                <Card
                    sx={{ width: 300 }}
                >
                    <CardMedia
                        sx={{ height: 200 }}
                        image='images/basuras.jpg'
                        title='recoleccion'
                    />
                    <CardContent >
                        <Typography gutterBottom variant="h5" component="div" >
                            Gestión de recolección de basuras
                        </Typography>
                        <Typography component={'div'} variant='subtitle1'>
                            Operaciones de campo
                        </Typography>
                        <Typography variant="body2" color="text.secondary" id='cardminhistoria'>
                            El sistema de Gerencia Satelital de flotas de vehículos GeSat, permite llevar un control
                            efectivo del cumplimiento de las rutas de recolección, monitorear los
                            indicadores de cumplimiento, recibir alertas de retrasos o incumplimientos
                            e identificar oportunidades para hacer mas eficiente la operación. Por otro lado, permite controlar
                            el consumo de combustible y los mantenimientos preventivos y correctivos de la flota de vehículos.
                        </Typography>
                    </CardContent>
                    <CardActions className={'portButCl'}>
                        <Button onClick={() => { nav('/historia/recoleccion') }}>Más información</Button>
                    </CardActions>

                </Card>
            </Grid>

            <Grid container justifyContent='center' xs={12} lg={4}>
                <Card
                    sx={{ maxWidth: 300 }}
                >
                    <CardMedia
                        sx={{ height: 200 }}
                        image='images/montacargas.jpg'
                        title='montacargas'
                    />
                    <CardContent className='espa2'>
                        <Typography gutterBottom variant="h5" component="div">
                            Control de mantenimiento de montacargas
                        </Typography>
                        <Typography component={'div'} variant='subtitle1'>
                            Operaciones de campo
                        </Typography>
                        <Typography variant="body2" color="text.secondary" id='cardminhistoria' >
                            Sistema que permite a las empresas que prestan servicios enviando personal
                            a las instalaciones de los clientes, efectuar de manera efectiva el seguimiento
                            de la visita y asegurar que el servicio se preste a tiempo de manera eficiente.
                        </Typography>

                    </CardContent>
                    <CardActions className={'portButCl'}>
                        <Button onClick={() => { nav('/historia/montacarga') }}>Más información</Button>
                    </CardActions>

                </Card>
            </Grid>


        </>
    )
}
