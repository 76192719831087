import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import DataContext from '../Context/DataContext'
import { RouteMiniNet } from '../routes/routeMiniNet'

export const Noticias = () => {

    const { todo, setCss } = useContext(DataContext)

    const loc = useLocation()

    useEffect(() => {

        setCss(loc.pathname, window.innerWidth)

        window.scrollTo(0, 0)

    }, [])

    return (
        <Grid container >

            <RouteMiniNet />

        </Grid>
    )
}
