import { createContext, useState } from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import axios from "axios";
const validator = require('../util/validator').default


const DataContext = createContext()

const url = process.env.REACT_APP_PSERVER
const token = process.env.REACT_APP_TOKEN

const DataProvider = ({ children }) => {
    const [todo, setTodo] = useState("")


    const setCss = (ruta, width) => {
        if (ruta === "/fundacion" && width <= 1368) {
            setTodo("")
        } else {
            setTodo("todo")
        }
    }

    const saveEmail = async (email) => {
        if (email !== "") {
            await axios.post(url + '/registrarCorreo', { email: email }, {
                headers:
                {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(
                    (response) => {
                        if (response.status === 200) {
                            if (response.data.res === "201") {
                                Swal.fire({
                                    position: 'top-end',
                                    title: 'Te has registrado',
                                    icon: 'success',
                                    timer: 2000,
                                    timerProgressBar: true,
                                    showConfirmButton: false,
                                })
                            } else if (response.data.res === "500") {
                                Swal.fire({
                                    position: 'top-end',
                                    title: 'Ya estas registrado',
                                    icon: 'warning',
                                    timer: 2000,
                                    timerProgressBar: true,
                                    showConfirmButton: false,
                                })
                            }
                        }
                    });
        } else {
            Swal.fire({
                position: 'top-end',
                title: 'No has ingresado nada',
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }

    const sendEmail = async (formData, tipo) => {

        switch (tipo) {
            case 'contacto':
                const errors = validator.validateForm(formData)

                if (errors !== undefined) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'No has ingresado este campo: ' + errors[0],
                        icon: 'error',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                } else {
                    await axios.post(url + '/enviarCorreo', formData, {
                        headers:
                        {
                            'content-type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    })
                        .then(response => {
                            if (response.status === 200) {
                                Swal.fire({
                                    position: 'top-end',
                                    title: 'Registro guardado',
                                    text: 'Gracias por comunicarte con nosotros',
                                    icon: 'success',
                                    timer: 3000,
                                    timerProgressBar: true,
                                    showConfirmButton: false,
                                })
                                document.getElementById('email').value = ''
                                document.getElementById('nombre').value = ''
                                document.getElementById('empresa').value = ''
                                document.getElementById('telefono').value = ''
                                document.getElementById('ciudad').value = ''
                                document.getElementById('comentario').value = ''
                            } else if (response.status === 500) {
                                Swal.fire({
                                    position: 'top-end',
                                    title: 'Ha ocurrido un error',
                                    icon: 'error',
                                    timer: 2000,
                                    timerProgressBar: true,
                                    showConfirmButton: false,
                                })
                            }
                        })
                }
                break;

            case 'hv':

                const errorsFile = validator.validateFile(formData)
                const errorsHv = validator.validateForm(formData)

                if (errorsHv !== undefined) {
                    Swal.fire({
                        position: 'top-end',
                        title: 'No has ingresado este campo: ' + errorsHv[0],
                        icon: 'error',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                } else {
                    if (errorsFile) {
                        await axios({
                            method: "post",
                            url: url + '/enviarCorreo',
                            data: formData,
                            headers: {
                                "Content-Type": "multipart/form-data",
                                'Authorization': `Bearer ${token}`
                            },
                        })
                            .then(response => {
                                if (response.status === 200) {
                                    Swal.fire({
                                        position: 'top-end',
                                        title: 'Registro guardado',
                                        text: 'Gracias por querer ser parte de nuestro equipo',
                                        icon: 'success',
                                        timer: 3500,
                                        timerProgressBar: true,
                                        showConfirmButton: false,
                                    })
                                    document.getElementById('email').value = ''
                                    document.getElementById('nombre').value = ''
                                    document.getElementById('comentario').value = ''
                                } else if (response.status === 500) {
                                    Swal.fire({
                                        position: 'top-end',
                                        title: 'Ha ocurrido un error',
                                        icon: 'error',
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showConfirmButton: false,
                                    })
                                }
                            })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            title: 'Tipo de archivo no valido',
                            text: 'Debe ser PDF',
                            icon: 'error',
                            timer: 3000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        })
                    }
                }
                break;

            default:
                break;
        }

    }

    const data = {
        todo,
        setCss, saveEmail, sendEmail
    }

    return <DataContext.Provider value={data}>{children}</DataContext.Provider>

}

export { DataProvider }
export default DataContext