

const validator = {
    validateForm: (form) => {
        for (const [key, data] of form.entries()) {
            if (data === '' || data === '0' || data === 'undefined') {
                const value = [key === 'file' ? 'CV' : key]
                return value !== undefined ? value : undefined
            }
        }
    },

    validateFile: (form) => {
        if (form.get('file').type !== 'application/pdf') {
            return false
        } else {
            return true
        }

    }
}

const _default = validator
export { _default as default }