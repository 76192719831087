import { Grid } from '@mui/material'
import React, { useEffect } from 'react'

export const Montacarga = () => {

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    {/*Agregar identación*/ }

    {/*Todos mismo tamaño de letra*/ }

    {/*Debajo de resultados poner un separador*/ }

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Control del Mantenimiento de Montacargas
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Operaciones de campo
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container id='divAntena'>
                <img src='images/montacargas.jpg' id='antena' />
            </Grid>

            <Grid container columns={{ md: 3 }} direction='row' id='row1Text'>
                <Grid container md={7} direction='column'>
                    <Grid item md={2} id='titleRow1'>
                        Cliente
                    </Grid>
                    <Grid item md={4} id='subtitleRow1'>
                        Royal, Carvajal, Coexito
                    </Grid>
                </Grid>

                <Grid container md={4} id='textRow1-t2'>
                    Sistema que permite a las empresas que prestan servicios enviando personal
                    a las instalaciones de los clientes, efectuar de manera efectiva el seguimiento de
                    la visita y asegurar que el servicio se preste a tiempo y de manera eficiente.
                </Grid>
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row2Text-t'>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3-t'>
                        Contexto
                    </Grid>
                    <Grid item id='textRow3-t' >
                        Se requiere mejorar la productividad y los indicadores de servicio para las actividades
                        de mantenimiento y reparación de montacargas que se encuentran distribuidos en diferentes clientes
                        y ciudades a lo largo del país y el continente.
                    </Grid>
                    <Grid item id='textRow3-t' >
                        Una vez activada una orden de servicio ya sea por un llamado del cliente o por mantenimiento preventivo
                        se requiere tener trazabilidad de todo el proceso buscando mejorar los tiempos de atención, mejorar el tiempo
                        de reparación y la efectividad.
                    </Grid>
                </Grid>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3' >
                        Solución
                    </Grid>

                    <Grid item id='textRow3-t'>
                        Se configuró el software y la App “Gestión de Visitas®” de tal manera que cada técnico recibe la orden
                        de servicio en su celular, allí mismo se reporta la atención, consumo de repuestos. Los administradores del
                        servicio pueden ver como se está ejecutando el servicio en tiempo real para cada cliente, montacargas y técnico.
                    </Grid>
                    <Grid item id='textRow3-t'>
                        La gerencia tiene información diaria de los indicadores de gestión que le permite tomar decisiones con la información al día.
                    </Grid>
                </Grid>

            </Grid>

            <Grid container columns={{ md: 1 }} id='row3Text' rowSpacing={{ md: 2 }}>
                <Grid item id='titleRow3'>
                    Resultados
                </Grid>
                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
                <Grid item id='textRow3'>
                    Control total del 100% de los servicios prestados con sus indicadores lo que ha permitido de manera objetiva calificar el profesionalismo de los técnicos,
                    identificar los costos del servicio,
                    optimizar los inventarios de repuestos que como resultado se mejoró el costo y tiempo de respuesta al cliente, permitiendo atender mas clientes
                    con la misma estructura de costos.
                </Grid>
            </Grid>
        </>
    )
}
