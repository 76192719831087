import { Grid } from '@mui/material'
import React, { useEffect } from 'react'

export const Pagos = () => {

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    {/*Agregar identación*/ }

    {/*Todos mismo tamaño de letra*/ }

    {/*Debajo de resultados poner un separador*/ }

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Plataforma para procesamiento pagos en efectivo
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Comunicaciones
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container id='divAntena'>
                <img src='images/mercado.png' id='antena' />
            </Grid>

            <Grid container columns={{ md: 3 }} direction='row' id='row1Text'>
                <Grid container md={7} direction='column'>
                    <Grid item md={2} id='titleRow1'>
                        Cliente
                    </Grid>
                    <Grid item md={4} id='subtitleRow1'>
                        Prisontec, INPE
                    </Grid>
                </Grid>

                <Grid container md={4} id='textRow1-t2'>
                    Sistema de procesamiento de transacciones que consolida los pagos en efectivo realizado por diferentes
                    medios tales como
                    redes de tiendas, billeteras electrónicas, con alcance nacional, con alto tráfico y alta seguridad.
                </Grid>
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row2Text-t'>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3-t'>
                        Contexto
                    </Grid>
                    <Grid item id='textRow3-t' >
                        Las empresas requieren recibir los pagos por sus servicios en sitios cercanos
                        a sus clientes que se encuentran distribuidos por todo el país.
                    <p></p>
                        Una vez activada una orden de servicio ya sea por un llamado del cliente o por mantenimiento preventivo
                        se requiere tener trazabilidad de todo el proceso buscando mejorar los tiempos de atención, mejorar el tiempo
                        de reparación y la efectividad.
                    </Grid>
                </Grid>

                <Grid container md={6}>
                    <Grid container md={1} id='titleRow3-c' >
                        Solución
                    </Grid>

                    <Grid container  md={12} id='textRow3-t2' textAlign={'justify'} style={{paddingBottom: '140px'}} >
                        Se desarrollo en la nube una plataforma con altos estándares de seguridad y con múltiples
                        protocolos que permite aseguran que los pagos se realicen de manera ágil y segura.
                    </Grid>
                </Grid>

            </Grid>

            <Grid container columns={{ md: 1 }} id='row3Text' rowSpacing={{ md: 2 }}>
                <Grid item id='titleRow3'>
                    Resultados
                </Grid>
                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
                <Grid item id='textRow3'>
                    Se procesan con éxito mas de 20.000 recargas al día originadas en redes de tiendas y aliados
                    por todo el país.
                </Grid>
            </Grid>
        </>
    )
}
