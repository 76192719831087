import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'

export const OpercacionesCampo = () => {

    const nav = useNavigate()

    return (
        <>
            <Grid id='titulosHisto'>
                Operaciones de Campo
            </Grid>
            < Grid container direction='row' columns={{ xs: 1, sm: 9 ,md: 9, lg: 9 }} id='subcontainerHisto'>
                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm:'normal', md: 'normal' }} paddingBottom={3} >
                    <Card
                        sx={{ width: 300, height: 640 }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/Citas.jpg'
                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Agendamiento amable y ágil de citas
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Operaciones de campo
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Sistema Agenda tu cita que permite a los clientes agendar
                                de manera ágil y amable sus citas 7x24 dando relevancia a las
                                preferencias del cliente ya sea por teléfono, por web,
                                WhatsApp usando automatización solo donde es relevante y mejorando la
                                experiencia del usuario.
                            </Typography>

                        </CardContent>

                        <CardActions className={'portButCl'}>
                            <Button onClick={() => { nav('/historia/citas') }}>Más información </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm:'normal',md: 'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ width: 300, height: 640 }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/montacargas.jpg'

                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Control de mantenimiento de montacargas
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Operaciones de campo
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Sistema que permite a las empresas que prestan servicios enviando personal
                                a las instalaciones de los clientes, efectuar de manera efectiva el seguimiento
                                de la visita y asegurar que el servicio se preste a tiempo de manera eficiente.
                            </Typography>
                        </CardContent>
                        <CardActions className={'portButCl'}>
                            <Button onClick={() => { nav('/historia/montacarga') }}>Más información</Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm:'normal', md: 'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ width: 300, height: 640 }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/basuras.jpg'

                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Gestión de recolección de basuras
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Operaciones de campo
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                El sistema de Gerencia Satelital de flotas de vehículos GSat, permite llevar un control
                                efectivo del cumplimiento de las rutas de recolección, monitorear los
                                indicadores de cumplimiento, recibir alertas de retrasos o incumplimientos
                                e identificar oportunidades para hacer mas eficiente la operación. Por otro lado, permite controlar
                                el consumo de combustible y los mantenimientos preventivos y correctivos de la flota de vehículos.
                            </Typography>
                        </CardContent>
                        <CardActions className={'portButCl'}>
                            <Button onClick={() => { nav('/historia/recoleccion') }}>Más información</Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm:'normal', md: 'normal' }} paddingBottom={5} >
                    <Card
                        sx={{ width: 300, height: 640 }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/globalCity.png'

                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Gestión de combustible para flotas de vehículos
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Operaciones de campo
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Permite que se asegure el uso correcto del combustible
                                a través de herramientas de medición
                                remota.
                                <br />
                                {/*Puntos en la lista*/}
                                <ul id='listCard'>
                                    <li>
                                        Consumo en kilómetros por galón para cada vehículo.
                                    </li>
                                    <li>
                                        Registro y reporte de suministro de combustible.
                                    </li>
                                    <li>
                                        Indicadores de consumo y eficiencia entre fechas.
                                    </li>
                                    <li>
                                        Registro de puntos de suministro de combustible autorizados.
                                    </li>
                                    <li>
                                        Sensores de medición exacta.
                                    </li>
                                </ul>
                            </Typography>
                        </CardContent>
                        <CardActions className={'portButCl'}>
                            <Button onClick={() => { nav('/historia/combustible') }}>Más información</Button>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid >


        </>
    )
}
