import { Button, FormControl, Grid, Input, TextField } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import DataContext from '../Context/DataContext'
import { MdOutlineEast } from 'react-icons/md'
import isEmail from 'validator/lib/isEmail'
import Swal from 'sweetalert2'


export const Contacto = () => {

    const { sendEmail } = useContext(DataContext)

    const handleSendEmail = () => {
        const formData = new FormData()

        formData.set('email', `${document.getElementById('email').value}`)
        formData.set('nombre', `${document.getElementById('nombre').value}`)
        formData.set('empresa', `${document.getElementById('empresa').value}`)
        formData.set('telefono', parseInt(`${document.getElementById('telefono').value === '' ? 0 : document.getElementById('telefono').value}`))
        formData.set('ciudad', `${document.getElementById('ciudad').value}`)
        formData.set('comentario', `${document.getElementById('comentario').value}`)
        formData.set('tipo', 'contacto')

        if (isEmail(document.getElementById('email').value)) {
            sendEmail(formData, 'contacto')
        } else {
            Swal.fire({
                position: 'top-end',
                title: 'Error en el email',
                icon: 'error',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }


    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Grid container>

                <Grid container columns={{ xs: 1, md: 2, lg: 2 }} direction='row' columnSpacing={{ sx: 24, md: 8 }} rowSpacing={{ xs: 3 }}>
                    <Grid item container xs={12} md={6}>
                        <Grid container md={12} justifyContent={'center'}>
                            <Grid md={10} item>
                                <img src='images/hermita.png' id='cali' />
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} rowSpacing={{ lg: 1, md: 2, xs: 4 }}>
                            <Grid item id='placeContact' xs={12} md={12}>
                                Cali, Colombia
                            </Grid>

                            <Grid item xs={12} md={3.4} id='textContact'>
                                Sistema de software para bloqueo de señal
                                de telefonía en las prisiones
                            </Grid>

                            <Grid item xs={12} md={12} id='numberContact'>
                                +57 602 3961263
                            </Grid>

                            <Grid item xs={12} md={12} id='numberContact'>
                                +57 318 232 2638
                            </Grid>

                            <Grid item xs={12} md={12} id='numberContact'>
                            Calle 14A # 106A - 175, Casa 54, Calle La Escopeta, Ciudad Jardín
                            </Grid>

                            <Grid item xs={12} lg id='emailContact'>
                                comercial@globalcircuit.co
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Grid container md={12} justifyContent={'center'}>
                            <Grid md={10} item>
                                <img src='images/playaPeru.png' id='cali' />
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} rowSpacing={{ lg: 1, md: 2, xs: 4 }}>
                            <Grid item id='placeContact' xs={12} md={12}>
                                Lima, Perú
                            </Grid>

                            <Grid item xs={12} md={3.4} id='textContact'>
                                Sistema de software para bloqueo de señal
                                de telefonía en las prisiones
                            </Grid>

                            <Grid item xs={12} md={12} id='numberContact'>
                                +51 1 257 6868
                            </Grid>

                            <Grid item xs={12} md={12} id='numberContact'>
                                +51 969 222 726
                            </Grid>

                            <Grid item xs={12} md={12} id='numberContact'>
                                Av del Pinar 180 oficina 1004 - Surco - LIMA
                            </Grid>

                            <Grid item xs={12} lg id='emailContact'>
                                comercial@globalcircuit.co
                            </Grid>

                        </Grid>


                    </Grid>
                </Grid>

                {/*Enviar correo*/}
                <Grid container columns={{ xs: 1, md: 1 }} direction='row' id='bloque3Contact'>
                    <Grid container xs={12} md={5} columns={{ xs: 1, md: 2 }} direction={'row'}>
                        <Grid item xs={12} md>
                            <div id='titleContac'>
                                Contáctanos
                            </div>
                        </Grid>
                        <Grid item xs={12} md={24}>
                            <img src="images/divisionHorizontal.png" id='division' alt='cell' />
                        </Grid>
                    </Grid>

                    <Grid container columns={{ xs: 1, md: 1 }} rowSpacing={{ md: 5, lg: 5, xs: 2 }} id='bloque3ContactForm'>
                        <Grid container item>
                            <table id='contact'>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='email' sx={{ color: '#141A51', fontWeight: 600, paddingLeft: '2vh' }} autoComplete='off' placeholder='E-mail*' disableUnderline />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='nombre' sx={{ color: '#141A51', fontWeight: 600 }} autoComplete='off' placeholder='Nombre*' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                            </table>
                        </Grid>

                        <Grid container item xs={12} md={12}>
                            <table id='contact'>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='empresa' sx={{ color: '#141A51', fontWeight: 600, paddingLeft: '2vh' }} autoComplete='off' placeholder='Empresa' disableUnderline />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='telefono' sx={{ color: '#141A51', fontWeight: 600 }} autoComplete='off' placeholder='Teléfono' type='number' disableUnderline />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='ciudad' sx={{ color: '#141A51', fontWeight: 600 }} autoComplete='off' placeholder='Ciudad' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                            </table>
                        </Grid>

                        <Grid container item xs md>
                            <TextField id='comentario' multiline placeholder='¿En qué podemos ayudarte?*' sx={{ color: '#141A51', fontWeight: 600, borderColor: '141A51' }} rows={6} fullWidth required />
                        </Grid>

                    </Grid>

                    {/*Movil*/}
                    <Grid container id='bloque3ContactFormCell'>

                        <Grid container>
                            <table id='contactCell'>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input autoComplete='off' placeholder='E-mail*' id='textFormCell' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input autoComplete='off' placeholder='Nombre*' id='textFormCell' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input autoComplete='off' placeholder='Empresa' id='textFormCell' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input autoComplete='off' placeholder='Teléfono' id='textFormCell' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input autoComplete='off' placeholder='Ciudad' id='textFormCell' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                            </table>
                        </Grid>

                        <Grid container item xs md>
                            <TextField multiline placeholder='¿En qué podemos ayudarte?*' sx={{ borderColor: '141A51' }} rows={6} fullWidth required />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container columns={{ xs: 1, md: 1, lg: 1 }} xs={2} md={12} lg={12} direction='row' id='sendCon' justifyItems={'right'}>
                    <Grid item md={2}>
                        <Button variant='contained' disableElevation id='sendCon' onClick={handleSendEmail}>Enviar <MdOutlineEast /> </Button>
                    </Grid>
                </Grid>


            </Grid>

        </>
    )
}
