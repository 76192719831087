import React, { useContext, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router'
import { Index } from '../components'
import { Construcion } from '../components/404'
import { Contacto } from '../components/contacto'
import { Fullvision } from '../components/fullvision'
import { Fundacion } from '../components/fundacion'
import { Historias } from '../components/historias'
import { DetalleGSat } from '../components/miniComponents/detalleGSat'
import { DetalleHistoria } from '../components/miniComponents/detalleHistoria'
import { Noticias } from '../components/noticias'
import { QueHacemos } from '../components/queHacemos'
import { QuienesSomos } from '../components/quienesSomos'
import { Trabaja } from '../components/trabaja'
import { Combustible } from '../components/miniComponents/combustible'
import DataContext from '../Context/DataContext'
import { NuestroTalento } from '../components/miniComponents/nuestroTalento'
import {Telefonia} from '../components/miniComponents/telefonia'
import {Citas} from '../components/miniComponents/citas'
import {Montacarga} from '../components/miniComponents/montacarga'
import { Recoleccion } from '../components/miniComponents/recoleccion'
import { Pagos } from '../components/miniComponents/pagos'
import { Combustible2 } from '../components/miniComponents/combustible2'

export const RoutePage = () => {
    const { setCss } = useContext(DataContext)

    const loc = useLocation()
    return (
        <Routes>
            <Route index action={useEffect(() => {

                setCss(loc.pathname, window.innerWidth)

            })} element={<Index />} />
            <Route path='quienesomos' action={useEffect(() => {

                setCss(loc.pathname, window.innerWidth)

            })} element={<QuienesSomos />} />
            <Route path='historia/*' element={<Historias />} />
            <Route path='historia/detallehis' element={<DetalleHistoria />} />
            <Route path='historia/detallegsat' element={<DetalleGSat />} />
            <Route path='noticias/*' element={<Noticias />} />
            <Route path='fullvision' element={<Fullvision />} />
            <Route path='trabaja' element={<Trabaja />} />
           <Route path='quehacemos' element={<QueHacemos />} />
            <Route path='fundacion' element={<Fundacion />} />
            <Route path='contacto' element={<Contacto />} />
            {/*<Route path='quehacemos' element={<NuestroTalento/>} />*/}
            <Route path='historia/combustible' element={<Combustible2 />} />
            <Route path='historia/telefonia' element={<Telefonia/>} />
            <Route path='historia/citas' element={<Citas/>} />
            <Route path='historia/montacarga' element={<Montacarga/>} />
            <Route path='historia/recoleccion' element={<Recoleccion/>} />
            <Route path='historia/pagos' element={<Pagos/>} />
            <Route path='*' element={<Construcion />} />
        </Routes>
    )
}


