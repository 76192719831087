import { Card, CardContent, CardMedia, Grid, Typography, createTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import DataContext from '../Context/DataContext'

export const Fundacion = () => {

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        pauseOnFocus: true,
        pauseOnHover: false,
        cssEase: "linear",

    }

    const { setCss } = useContext(DataContext)

    const loc = useLocation()

   useEffect(() => {

        setCss(loc.pathname, window.innerWidth)
        window.scrollTo(0, 0)

    }, [])

    const items = [{
        items: [
            {
                image: 'images/peru.png',
            }, {
                image: 'images/fun1.png',
            }, {
                image: 'images/fun2.png',
            }, {
                image: 'images/fun3.png',

            },
        ]
    }
    ]


    let comps = []
    let spacing

    if (window.innerWidth <= 1368) {
        spacing = 5
    } else if (window.innerWidth >= 1870) {
        spacing = 15
    }

    for (let index = 0; index < items.length; index++) {
        comps.push(
            <Grid container id='objIn' columns={{ xs: 1, md: 9, lg: 9 }} columnSpacing={{ md: 3, lg: 3 }} rowSpacing={{ xs: 0, md: 0, lg: 0 }} direction='row'>{
                items[index].items.map((obj) =>
                    <Grid container justifyContent={'center'} xs={12} md={4} lg={4}>
                        <Card sx={{ maxWidth: 200 }}>
                            <CardMedia sx={{ height: 200 }} image={obj.image} />
                        </Card>
                    </Grid>)
            }</Grid>
        )
    }


    return (
        <>



            <div className={'slider'} >
                <Slider {...settings} >
                    {items[0].items.map((obj) =>
                        <Card sx={{ width: 800 }}>
                            <CardMedia sx={{ height: 720 }} image={obj.image} />
                        </Card>
                    )
                    }
                </Slider>
            </div>

            < Grid container >
                <Grid container xs={12}>
                    <img src='images/peru.png' id='fundacion' />
                </Grid>

                <Grid container columns={{ md: 12 }} direction='row' id='bloq1Fun' columnSpacing={15}>

                    <Grid item md={3}>
                        <img src='images/libertad.png' />
                    </Grid>

                    <Grid item md={5}>
                        Libertad en mi prisión busca impactar de manera positiva la vida de las personas
                        privadas de la libertad y de sus familias a través de programas
                        de resocialización y acompañamiento
                    </Grid>

                    <Grid item md={4}>
                        <Link to='*'>
                            <Grid container direction={'row'} columns={{ md: 2 }} id='linkFun'>
                                <Grid item id='fun' >
                                    Ir a la página
                                </Grid>
                                <Grid item xs>
                                    <img src='images/arrowRed.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                </Grid>

                <Grid container columns={{ md: 2 }} direction='row' id='bloqImgs' columnSpacing={4}>

                    <Grid item container columns={{ md: 2 }} direction='row' xs columnSpacing={4} rowSpacing={5}>
                        <Grid item>
                            <img src='images/fun1.png' id='img1' />
                        </Grid>
                        <Grid item>
                            <img src='images/fun2.png' id='img1' />
                        </Grid>
                        <Grid item>
                            <img src='images/fun3.png' id='img2' />
                        </Grid>
                    </Grid>

                    <Grid item container direction={'column'} xs>
                        <Grid item >
                            <img src='images/fun5.png' id='img3' />
                        </Grid>
                    </Grid>

                </Grid>

                {/*movil*/}



                <Grid container className='moviltexto' direction='row' id='bloq1Fun1'  >

                    <Grid container xs={12} id='txt1'>
                        Libertad en mi prisión busca impactar de manera positiva la vida de las personas
                        privadas de la libertad y de sus familias a través de programas
                        de resocialización y acompañamiento
                    </Grid>

                    <Grid container xs={6} >
                        <img src='images/libertad.png' id='libertad' />
                    </Grid>

                    <Grid container xs={6} >
                        <Link to='*'>
                            <Grid container direction={'row'} columns={{ xs: 2 }} id='linkFun'>
                                <Grid item id='fun1'>
                                    Ir a la página
                                </Grid>
                                <Grid item >
                                    <img src='images/arrowRed.png' id='arrow' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                </Grid>

            </Grid>

        </>
    )
}
