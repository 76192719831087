
import { Close } from '@mui/icons-material';
import { Grid, Modal } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu'
import TuneIcon from '@mui/icons-material/Tune';

export const Filtrohis = () => {

    const nav = useNavigate()


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);


    const handleClose = (e) => {
        setOpen(false);

        switch (e.target.id) {
            case 'logoCell':
                nav('')
                break;

            case 'operaciones':
                nav('/historia/operaciones')
                break;

            case 'ciudadsegura':
                nav('/historia/ciudadsegura')
                break;
            case 'comunicaciones':
                nav('/historia/comunicaciones')
                break;

            default:
                break;
        }


    }
    return (
        <>

            <Grid onClick={handleOpen} >
                <TuneIcon sx={{ fontSize: 30 }} />
            </Grid>


            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                onClose={handleClose}
                aria-describedby="modal-modal-description"
                id='menu'>

                <Grid container sx={{ backgroundColor: "#257AF4", height: '100%' }} id='cont'>

                    <Grid container className='padCell' direction={'column'}>
                        <Grid container columns={{ xs: 2 }}>
                            <Grid item id='/' xs onClick={handleClose}>
                                <img src='images/LogoBlancoGlobal.png' id='logoCell' />
                            </Grid>
                            <Grid container justifyContent={'right'} xs onClick={handleClose}>
                                <Close />
                            </Grid>
                        </Grid>


                        <Grid container columns={{ xs: 1 }} rowSpacing={2}  direction={'column'} id='rowMenu' style={{marginLeft:'10px'}} >
                            <Grid item id='historias' onClick={handleClose} className='col1Menu'>
                                Nuestras historias
                            </Grid>
                            <Grid item id='operaciones' onClick={handleClose} className='col2Menu'>
                                Operaciones de campo
                            </Grid>
                            <Grid item id='comunicaciones' onClick={handleClose} className='col2Menu'>
                                comunicaciones
                            </Grid>
                            <Grid item id='ciudadsegura' onClick={handleClose} className='col2Menu'>
                                Seguridad
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

        </>
    )

}
