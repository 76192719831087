import { Grid } from '@mui/material'
import React, { useEffect } from 'react'

export const DetalleHistoria = () => {

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    {/*Agregar identación*/ }

    {/*Todos mismo tamaño de letra*/ }

    {/*Debajo de resultados poner un separador*/ }

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Bloqueo de señales inalámbricas en Prisiones
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Comunicaciones
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container id='divAntena'>
                <img src='images/antena2.png' id='antena' />
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row1Text'>
                <Grid container md={7} direction='column'>
                    <Grid item md={2} id='titleRow1'>
                        Cliente
                    </Grid>
                    <Grid item md={4} id='subtitleRow1'>
                        Ministerio de Justicia de Perú
                    </Grid>
                </Grid>

                <Grid container md={5} id='textRow1'>
                    Sistema electrónico que impide el uso de celulares dentro de las
                    prisiones, bloqueando las señales inalámbricas
                </Grid>
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row2Text'>

                <Grid container md={6}>
                    <Grid item  id='titleRow3' >
                        Contexto
                    </Grid>

                    <Grid item md={9.6} id='textRow3-t' >
                        El estado peruano requiere un sistema para evitar
                        las extorsiones telefónicas realizadas
                        desde el interior de las prisiones
                    </Grid>
                </Grid>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3' >
                        Solución
                    </Grid>

                    <Grid item id='textRow3'>
                        Global Circuit diseñó un sistema electrónico que mediante la generación
                        de interferencia inhibe el funcionamiento de los teléfonos celulares
                        al interior de las prisiones
                    </Grid>
                </Grid>

            </Grid>

            <Grid container columns={{ md: 1 }} id='row3Text' rowSpacing={{ md: 2 }}>
                <Grid item id='titleRow3'>
                    Resultados
                </Grid>
                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
                <Grid item id='textRow3'>
                    El primer sistema se instaló en el 2016 y a la fecha se ha instalado
                    en los 29 establecimientos penitenciarios más importantes del Perú, atendiendo
                    a mas de 70.000 internos que suman el 85% de la población penitenciaria del país,
                    logrando disminuir dramáticamente el fenómeno de la extorsión.
                </Grid>
            </Grid>

            <Grid container columns={{ md: 1 }} id='row4Text'>
                <Grid item id='textRow4'>
                    Se acompaña este proyecto con el programa social Libertad en mi Prisión
                    que ayuda a las personas privadas de la libertad a conseguir herramientas
                    que hacen más llevadera su condena y les permitan en el futuro enfrentar de mejor manera
                    su reincorporación a la sociedad.
                </Grid>
            </Grid>

        </>
    )
}
