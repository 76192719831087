import { FormControl, Grid, Input, InputBase, InputLabel, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DataContext from '../Context/DataContext'

export const Footer = () => {

    const { saveEmail } = useContext(DataContext)

    const [word, setWord] = useState("e-mail")
    const [word2, setword2] = useState("Suscríbete si te interesa conocer sobre nuestro trabajo")
    const [word3, setWord3] = useState("Enviar")
    const [anchoScreen, setAnchoScreen] = useState(0)

    const handleSave = () => {
        saveEmail(document.getElementById('user').value)
        document.getElementById('user').value = ""
    }

    useEffect(() => {
        if ((window.innerWidth >= 280) && (window.innerWidth <= 480)) {
            setword2('')
        } else {
            setword2('Suscríbete si te interesa conocer sobre nuestro trabajo')
        }
    }, [window.innerWidth])



    return (
        <div id='foot'>
            <footer>
                <Grid container direction={'column'} columns={{ xs: 1, md: 1, lg: 1 }} rowSpacing={{ md: 5, xs: 3 }}>
                    <Grid xs>
                        <img src='images/LogoBlancoGlobal.png' />
                    </Grid>

                    <Grid id="secondRow" container columnSpacing={{ xd: 7, md: 14 }} xs direction='row'>
                        <Grid item xs='auto'>
                            <Link to={'quienesomos'}>
                                <div id='first'>
                                    ¿Quiénes Somos?
                                </div>
                            </Link>

                            <Link to={'quehacemos'}>
                                <div>
                                    ¿Qué hacemos?
                                </div>
                            </Link>

                            <Link to={'noticias'}>
                                <div>
                                    Noticias
                                </div>
                            </Link>

                        </Grid>
                        <Grid item xs='auto'>
                            <Link to={'historia'}>
                                <div id='first'>
                                    Nuestras historias
                                </div>
                            </Link>
                            <Link to={'historia/operaciones'}>
                                <div>
                                    Operaciones de campo
                                </div>
                            </Link>
                            <Link to={'historia/comunicaciones'}>
                                <div>
                                    Comunicaciones
                                </div>
                            </Link>
                            <Link to={'historia/ciudadsegura'}>
                                <div>
                                    Ciudad segura
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs='auto'>
                            <div id='first'>
                                Responsabilidad Social
                            </div>
                            <Link to={'fundacion'}>
                                <div>
                                    Libertad en mi Prisión
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs='auto'>
                            <Link to={'trabaja'}>
                                <div id='first'>
                                    Trabaja con nosotros
                                </div>
                            </Link>
                            <Link to={'contacto'}>
                                <div id='first'>
                                    Contacto
                                </div>
                            </Link>
                        </Grid>
                    </Grid>


                    <Grid container xs md id='input' justifyContent={'center'} style={{ display: 'none' }}>
                        <table id='foot'>
                            <tr>

                                <td id='secondT'>
                                    <FormControl variant='standard' fullWidth>
                                        <TextField variant='standard' InputProps={{ disableUnderline: true }} id='user' placeholder={word2} />
                                    </FormControl>

                                </td>
                                <td id='send'>
                                    <tr onClick={handleSave} >
                                        <td id='tirthT'>
                                            {word3}
                                        </td>
                                        <td>
                                            <img src='images/arrow.png' />
                                        </td>
                                    </tr>
                                </td>
                            </tr>
                        </table>
                    </Grid>


                    <Grid container xd xs={12}md={12} id='lastRow'>
                        <Grid item xs={12} md={12}>
                            <div>
                                Todos los derechos reservados Global Circuit 2024
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </footer>
        </div>
    )
}
