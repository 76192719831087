import { Button, FormControl, Grid, Input, InputLabel, TextField, useFormControl } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import DataContext from '../Context/DataContext'
import { MdOutlineEast } from 'react-icons/md'
import isEmail from 'validator/lib/isEmail'
import Swal from 'sweetalert2'

/*quitar sombra tarjetas cambiar flechas a unas x3*/

export const Trabaja = () => {

    const { todo, setCss, sendEmail } = useContext(DataContext)

    const loc = useLocation()

    const fileInput = useRef();

    const [file, setFile] = useState("")
    const [color, setColor] = useState("#A5A4A4")

    useEffect(() => {

        setCss(loc.pathname, window.innerWidth)
        window.scrollTo(0, 0)

    }, [])

    const handleSendEmail = () => {
        const formData = new FormData()

        const email = document.getElementById('email').value
        const nombre = document.getElementById('nombre').value
        const comentario = document.getElementById('comentario').value
        const file = document.getElementById('file').files[0]

        if (isEmail(email)) {
            formData.set('email', `${email}`)
            formData.set('nombre', `${nombre}`)
            formData.set('comentario', `${comentario}`)
            formData.set('tipo', 'hv')
            formData.append('file', file)

            sendEmail(formData, 'hv')
        } else {
            Swal.fire({
                position: 'top-end',
                title: 'Error en el email',
                icon: 'error',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }



    }

    const handleFile = () => {
        setFile(document.getElementById('file').files.item(0).name)
    }

    return (
        <>
            <Grid container>
                <Grid container item columns={{ xs: 1, md: 1 }} direction='row'>
                    <Grid item container id='work' direction={'row'}>
                        <Grid item xs md>
                            <div>
                                Trabaja con Nosotros
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>

                            <img src="images/divisionHorizontal.png" id='division' alt='cell' />

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid item container columns={{ xs: 2, md: 12 }} direction='row' id='bloque1Work'>
                        <Grid item container md={6} id='bloq1' direction={'row'}>
                            <Grid item md={8}>
                                ¿Por qué Global Circuit?
                            </Grid>
                            <Grid item md={12}>
                                Global Circuit desarrolla proyectos de IT novedosos que implican
                                investigación en telecomunicaciones, software, hardware, seguridad e
                                inteligencia artificial, que reta a adquirir nuevos conocimientos y destrezas
                                en nuevas áreas. La diversidad de proyectos hace que cada día sea único y sorprendente.
                            </Grid>
                        </Grid>

                        <Grid container md={6}>
                            <Grid container item md={12}>
                                <img src='images/grupoOficina.png' id='imgWork' />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container columns={{ xs: 2, md: 2 }} direction='row' id='bloque1Work'>
                        <Grid item continer md={1} id='bloq1'>
                            <Grid item>
                                Nuestra cultura
                            </Grid>
                            <Grid item md={1.5}>
                                Valoramos por encima de cualquier cosa la calidad de vida de las personas,
                                su desarrollo integral no solo profesional si no también familiar y espiritual,
                                buscamos siempre que nuestro trabajo tenga impacto positivo en el bienestar de la sociedad.
                            </Grid>
                        </Grid>

                        <Grid item container md={6}>
                            <Grid item md={12}>
                                
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid container columns={{ xs: 1, md: 1 }} direction='row'>
                    <Grid container id='chance' direction={'row'}>
                        <Grid item md>
                            <div>
                                Oportunidades de trabajo
                            </div>
                        </Grid>
                        <Grid item md={12}>

                            <img src="images/divisionHorizontal.png" id='division' alt='cell' />

                        </Grid>
                    </Grid>

                    <Grid container columns={{ xs: 1, md: 3, lg: 3 }} spacing={{ xs: 3, md: 15, lg: 15 }} id='bloque2Work'>

                        <Grid item container direction={'column'} xs={12} md={3} lg={3} id='textWork'>

                            <Grid item id='firstWork'>
                                Desarrollo de software
                            </Grid>

                            <Grid item id='secondWork'>
                                Habilidades de programación en diferentes lenguajes y capacidad de
                                trabajar en equipo. trabajo remoto y/o alternancia para Cali, Lima.
                            </Grid>

                            <Grid item id='thirdWork'>
                                Estudiantes y profesionales de ingeniería de sistemas.
                            </Grid>

                        </Grid>

                        <Grid item id='vect'>
                            <img src='images/Vector.png' id='vect' />
                        </Grid>

                        <Grid item container direction={'column'} xs={12} md={3} lg={3} id='textWork'>
                            <Grid item id='firstWork'>
                                Desarrollo de sistemas de comunicaciones
                            </Grid>

                            <Grid item id='secondWork'>
                                Experiencia en desarrollo con microcontroladores, conocimientos de radio
                                comunicaciones, telefonía celular y antenas. Alternancia en Cali, Lima.
                            </Grid>

                            <Grid item id='thirdWork'>
                                Estudiantes y profesionales de ingeniería de sistemas.
                            </Grid>
                        </Grid>

                        <Grid item id='vect'>
                            <img src='images/Vector.png' id='vect' />
                        </Grid>

                        <Grid item container direction={'column'} xs={12} md={3} lg={3} id='textWork'>
                            <Grid item id='firstWork'>
                                Venta de soluciones
                            </Grid>

                            <Grid item id='secondWork'>
                                Experiencia en ventas de tecnología y/o software, alternancia Cali.
                            </Grid>

                            <Grid item id='thirdWork'>
                                Profesionales con gusto por las ventas y la tecnología.
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container columns={{ xs: 1, md: 2, lg: 2 }} direction='row' id='bloque3Work'>
                    <Grid container xs={12} md={5} lg={5} direction={'row'}>
                        <Grid item xs md lg>
                            <div id='chance'>
                                Haz parte de Global Circuit
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container columns={{ xs: 1, md: 1, lg: 1 }} rowSpacing={{ xs: 1, md: 5, lg: 5 }} id='bloque3Form'>
                        <Grid container item>
                            <table id='work'>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='email' sx={{ color: color, paddingLeft: '2vh' }} autoComplete='off' placeholder='E-mail' disableUnderline />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='nombre' sx={{ color: color }} autoComplete='off' placeholder='Nombre' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                            </table>



                            {/*Movil*/}

                            <table id='workCell'>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='textFormCellW' autoComplete='off' placeholder='E-mail' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormControl variant='standard' fullWidth>
                                            <Input id='textFormCellW' autoComplete='off' placeholder='Nombre' disableUnderline />
                                        </FormControl>
                                    </td>
                                </tr>
                            </table>

                        </Grid>

                        <Grid container item xs={12} md={12} lg={12}>
                            <table id='tableFile'>
                                <tr>
                                    <td>
                                        <Button onClick={() => fileInput.current.click()}>
                                            Cargar CV
                                        </Button>
                                        <br />
                                        {file}
                                        <input ref={fileInput} type={'file'} style={{ display: 'none' }} id='file' onChange={handleFile} />
                                    </td>
                                </tr>
                            </table>
                        </Grid>

                        <Grid container item xs md lg>
                            <TextField id='comentario' multiline placeholder='¿Por qué quieres trabajar con nosotros?' rows={6} fullWidth />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container columns={{ xs: 1, md: 1, lg: 1 }} xs={2} md={12} lg={12} direction='row' id='sendTr' justifyItems={'right'}>
                    <Grid item md={2}>
                        <Button variant='contained' disableElevation id='sendTr' onClick={handleSendEmail}>Postúlate <MdOutlineEast /> </Button>
                    </Grid>
                </Grid>


            </Grid>

        </>
    )
}
