import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

export const Combustible2 = () => {

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Gestión de combustible para flotas de vehículos
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Operaciones de campo
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container id='divAntena'>
                <img src='images/ImagenGasolina.jpeg' id='imgcity' />
            </Grid>

            <Grid container columns={{ md: 3 }} direction='row' id='row1Text'>
                <Grid container md={7} direction='column'>
                    <Grid item md={2} id='titleRow1'>
                        Cliente
                    </Grid>
                    <Grid item md={4} id='subtitleRow1'>
                        Servi Aseo, Aseo Ya
                    </Grid>
                </Grid>

                <Grid container md={4} id='textRow1-t2'>
                    Sistema que permite a las empresas puedan realizar una medición directa del paso de combustible al motor de manera muy precisa pensado para motores de gran consumo.
                </Grid>
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row2Text-t'>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3-t'>
                        Contexto
                    </Grid>
                    <Grid item id='textRow3-t' >
                        La medición del combustible es parte importante de las empresas que dependen de la movilización
                        es por eso que apoyarse en las nuevas tecnologías para lograr la medición automática y exacta de esta
                        será de gran ayuda en su día a día.
                    </Grid>
                </Grid>

                <Grid container md={6}>
                    <Grid container md={3} id='titleRow3-t' >
                        Solución
                    </Grid>

                    <Grid container md={10} id='textRow3-t'  >
                        Se instala un dispositivo en el tanque que captura permanentemente la información del nivel de combustible,
                        su instalación no requiere modificar el tanque ni conexiones adicionales al sensor del vehículo que puedan alterar su operación.
                    </Grid>
                </Grid>

            </Grid>

            <Grid container columns={{ md: 1 }} id='row3Text' rowSpacing={{ md: 2 }}>
                <Grid item id='titleRow3'>
                    Resultados
                </Grid>
                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
                <Grid item id='textRow3'>
                    Se a logrado una disminución en la perdida de combustible en los vehículos que se ha instalado el dispositivo. 
                </Grid>
            </Grid>
        </>
    )
}
