import { Box, Grid, Paper, Popper } from '@mui/material';
import './App.css';
import { Footer } from './components/footer';
import { NavBar } from './components/navBar';

import './styles/queHacemos.css'
import './styles/nav.css'
import './styles/menu.css'
import './styles/detalleHis.css'
import './styles/contacto.css'
import './styles/footer.css'
import './styles/historias.css'
import './styles/index.css'
import './styles/quienesSomos.css'
import './styles/noticias.css'
import './styles/fullvision.css'
import './styles/trabaja.css'
import './styles/fundacion.css'
import './styles/ciudadSegura.css'
import './styles/carousel.css'
import './styles/combustible.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { RoutePage } from './routes/routes';
import { useContext, useEffect } from 'react';
import DataContext from './Context/DataContext';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

function App() {

  const { todo, setCss } = useContext(DataContext)

  const loc = useLocation()

  useEffect(() => {

    setCss(loc.pathname, window.innerWidth)

  }, [])
document.addEventListener('DOMContentLoaded', (event) => {
  const headernav = document.querySelector('.pad2');

        window.addEventListener('scroll',()=>{
            if(window.scrollY > 50){
                headernav.classList.add('nav.scroll')
            }
        })
      })
  return (
    <>

      <div className='nav.scroll' id='' style={{ position: 'fixed', width: '100%', zIndex: 1, backgroundColor: 'white' }} >
        <NavBar />
      </div>


      <div style={{ paddingTop: '20vh' }}>
      </div>
      <div id={todo}>
        <RoutePage />
      </div>

      <Footer />

    </>
  );
}

export default App;
