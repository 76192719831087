import { Grid } from '@mui/material'
import React from 'react'

export const Construcion = () => {
    return (
        <>
            <Grid container id='containerBob' direction={'column'} alignItems='center'>
                <Grid item>
                    <img src='images/bob.gif' />
                </Grid>
                <Grid item>
                    Esto aun se esta construyendo
                </Grid>
            </Grid>
        </>
    )
}
