import { Avatar, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import DataContext from '../Context/DataContext'
import { Fullvision } from './fullvision'



export const QueHacemos = () => {

    const { todo, setCss } = useContext(DataContext)

    const loc = useLocation()

    useEffect(() => {
        //setCss(loc.pathname, window.innerWidth)
    }, [])

    useEffect(() => {
        if (window.innerWidth >= 480 && window.innerHeight <= 648) {
            if (loc.state !== null) {
                window.scrollTo(0, 979)
            } else {
                window.scrollTo(0, 0)
            }
        } else if (window.innerWidth > 648 && window.innerHeight <= 980) {
            if (loc.state !== null) {
                window.scrollTo(0, 1482)
            } else {
                window.scrollTo(0, 0)
            }
        }

    }, [loc.state])

    return (
        <>


            <Grid container>

                <Grid container>
                    <div className="video-wrapperQH">
                        <video autoPlay loop muted>
                            <source src="videos/que_hacemos.mp4" type="video/mp4" />
                        </video>
                    </div>
                </Grid>

                <Grid container item columns={{ x: 1, md: 2, lg: 2 }} direction='row' id='textElec'>
                    <Grid container item id='text1Elec' xs={12} md={3} lg={3}>
                        ¿Qué hacemos?
                    </Grid>
                    <Grid container item id='text2Elec' xs={12} md={6} lg={6}>
                        Trabajamos desarrollando soluciones de hardware y software que
                        permitan mejorar la productividad y la seguridad de nuestros clientes.
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <img src="images/divisionHorizontal.png" id='division' alt='cell' />
                </Grid>

                <Grid container item id='bloq2Elec'>
                    <Grid container item>
                        Para cada cliente hay una solución única
                    </Grid>
                    <Grid container item>
                        Pasos del proceso
                    </Grid>

                    <Grid container item columns={{ xs: 1, md: 9, lg: 9, }} direction='row' spacing={{ xs: 2, md: 5, lg: 5 }} >

                        <Grid container item direction={'column'} xs={12} md={6} lg={6}>
                            <Grid item id='Title'>
                                1. Análisis
                            </Grid>
                            <Grid item id='text'>
                                Analizamos las necesidades del cliente y buscamos soluciones
                                tecnológicas y operativas que mejoren la efectividad.
                            </Grid>
                        </Grid>

                        <Grid container item direction={'column'} xs={12} md={6} lg={6}>
                            <Grid item id='Title'>
                                2. Solución
                            </Grid>
                            <Grid item id='text'>
                                Incorporamos hardware y software de última
                                generación con una vida útil de largo plazo con bajo impacto
                                en la estructura de costos del cliente
                            </Grid>
                        </Grid>

                        <Grid container item direction={'column'} xs={12} md={6} lg={6}>
                            <Grid item id='Title'>
                                3. Implementación
                            </Grid>
                            <Grid item id='text'>
                                Instalamos la solución con altos estándares de calidad
                                y damos capacitación a los usuarios para que puedan sacar máximo provecho
                                de la tecnología
                            </Grid>
                        </Grid>


                        <Grid container item direction={'column'} xs={12} md={6} lg={6}>
                            <Grid item id='Title'>
                                4. Soporte
                            </Grid>
                            <Grid item id='text'>
                                Acompañamos al cliente permanentemente durante la operación
                                de la solución, estamos con él en las mejoras necesarias que surgen
                                del uso diario y en la evolución que se va dando en el tiempo
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container item id='div'>
                    <img src='images/divisionHorizontal.png' id='division' alt='cell' />
                </Grid>
                <Fullvision />


                <Grid container item id='div'>
                    <img src='images/divisionHorizontal.png' id='division' alt='cell' />
                </Grid>


                <Grid container id='bloq3Elec'> Nuestro Talento </Grid>


                <Grid container md={12} id='container presentacion' >

                    <Grid container justifyContent='center' xs={12} lg={4} md={6} id='espacards' >
                        <Card sx={{ maxWidth: 400, height: 'auto' }}>

                            <CardHeader title={'DIEGO VELASCO'} subheader={'Gerente General'} avatar={
                                <Avatar sx={{ height: 100, width: 100 }} src='images/Diego.png' title='Diego Velasco' id='imgPre' />}>
                            </CardHeader>

                            <CardContent>
                                <Typography variant="body2" color="text.secondary" align='justify'>
                                    Ingeniero en electronica con mas de 30 años de experiencia ha participado en más de 200 soluciones tecnológicas para las
                                    organizaciones privadas y estatales de América Latina. Fundador de empresas en Colombia, Perú, Ecuador, Estados Unidos.
                                    Cree que el desarrollo del talento humano fundamentado en principios es la base para una sociedad más justa y próspera.
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>

                    <Grid container justifyContent='center' xs={12} lg={4} md={6} id='espacards'>
                        <Card sx={{ maxWidth: 400 }}>

                            <CardHeader title={'SANDRA GUTIERREZ'} subheader={'Gerencia comercial'} avatar={
                                <Avatar sx={{ height: 100, width: 100 }} src='images/Sandra-Gutierrez-Web.png' title='Sandra Gutiérrez' id='imgPre' />}>
                            </CardHeader>

                            <CardContent>
                                <Typography variant="body2" color="text.secondary" align='justify'>
                                Administradora de Empresas con especialización en gerencia de ventas y banca, Magister en Derechos Humanos y cultura de paz. 
                                    <p></p>
                                    Mi pasión es ayudar a las personas privadas de la libertad a encontrar su propósito en la vida y  trabajar en el desarrollo 
                                    humano de nuestros colaboradores, creyendo firmemente que cada individuo tiene un potencial único que merece ser cultivado y apoyado.
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>

                    <Grid container justifyContent='center' xs={12} lg={4} md={6} id='espacards' >
                        <Card sx={{ maxWidth: 400 }}>

                            <CardHeader title={'ANDRES COLLAZOS MONTES DE OCA'} subheader={'Director de Ingeniería'} avatar={
                                <Avatar sx={{ height: 100, width: 100 }} src='images/img_andrescollazos.jpeg' title='Andrés Collazos Montes de Oca' id='imgPre' />}>
                            </CardHeader>

                            <CardContent>
                                <Typography variant="body2" color="text.secondary" align='justify'>
                                    Ingeniero Electricista con más de 30 años de experiencia en diseño, desarrollo e implementación de proyectos en el área de electrónica, 
                                    así como en el diseño e implementación de proyectos de hardware y software en Latinoamérica.
                                    <p></p>
                                    El talento humano junto con la experiencia llevan a un mejoramiento continuo en todos los procesos y 
                                    productos de la empresa llevando satisfacción a los clientes sin perder de vista el ser humano.
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>

                    <Grid container justifyContent='center' xs={12} lg={4} md={6} id='espacards'>
                        <Card sx={{ maxWidth: 400 }}>

                            <CardHeader title={'FRANCISCO JOSÉ NAUFFAL PANESSO'} subheader={'Director de Proyectos'} avatar={
                                <Avatar sx={{ height: 100, width: 100 }} src='images/RostroFrancisco.jpg' title='Francisco Nauffal' id='imgPre' />}>
                            </CardHeader>

                            <CardContent>
                                <Typography variant="body2" color="text.secondary" align='justify'>
                                    Ingeniero en electrónica y telecomunicaciones con especialización en tecnologías de las
                                    telecomunicaciones y más de 30 años de experiencia en el desarrollo de productos, gerencia de operaciones y proyectos de empresas de TI.
                                    <p></p>
                                    Estoy convencido que la simbiosis entre las características y calidad del producto, la eficiencia de los procesos para desarrollar el producto y el talento de las
                                    personas que lo desarrollan son fundamentales para el éxito de los proyectos.
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                    
                </Grid>
            </Grid>

        </>
    )
}
