import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'

export const Comunicaciones = () => {

    const nav = useNavigate()

    return (
        <>
        <Grid id='titulosHisto'> Comunicaciones</Grid>
            < Grid container direction='row' columns={{ xs: 1, sm: 9, md: 9, lg: 9 }} id='subcontainerHisto'>
                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm:'normal', md: 'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ maxWidth: 300, height: 640 }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 400 }}
                            image='images/penal.png'

                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div" id='text1H'>
                                Telefonía segura para penitenciarías
                            </Typography>
                            <Typography component={'div'} variant='subtitle1' id='text2H'>
                                Comunicaciones
                            </Typography>
                            <Typography variant="body2" color="text.secondary" >
                                Sistema de teléfonos y videoteléfonos especiales para penitenciarías
                                que permite establecer comunicaciones seguras para la óptima comunicación
                                de los internos. Este sistema permite a las autoridades mantener el control
                                de las comunicaciones y mediante el Sistema de Inteligencia Penitenciario (SIP)
                                realizar investigaciones efectivas de delitos cometidos desde las prisiones.
                            </Typography>
                        </CardContent>
                        <CardActions className={'portButCl'}>
                            <Button onClick={() => { nav('/historia/telefonia') }}>Más información </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid container xs={12}  sm={6} md={4} justifyContent={{ xs: 'center', sm:'normal', md: 'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ maxWidth: 300, height: 640 }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            
                            image='images/mercado.png'

                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Plataforma para el procesamiento de pagos en efectivo
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Comunicaciones
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Sistema de procesamiento de transacciones que consolida los pagos en efectivo
                                realizado por diferentes medios tales como redes de tiendas,
                                billeteras electrónicas con alcance nacional, con alto tráfico y alta seguridad.
                            </Typography>
                        </CardContent>
                        <CardActions className={'portButCl'}>
                            <Button onClick={() => { nav('/historia/pagos') }}>Más información</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
