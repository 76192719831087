import { Button, Card, CardContent, CardMedia, Grid, Typography, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { MiniHistorias } from './miniComponents/miniHistorias'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';






const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    pauseOnFocus: true

}

const cardTxtmain = createTheme({
    typography: {
        fontSize: 18,
        fontWeightMedium: 500,
    },
})




const items = [{
    items: [
        {
            image: 'images/carcel.png',
            title: 'Seguridad tecnológica para 33 penitenciarias en Perú',
        }, {
            image: 'images/kokoriko.png',
            title: 'Mejoramiento tecnológico de la operación y seguridad de los agentes de tránsito para Cali',
        }, {
            image: 'images/ministro.png',
            title: 'Ministro de justicia inaugura sistema de bloque en la penitenciaria de Huaral Perú',
        }, {
            image: 'images/bloqueo.jpg',
            title: 'Solución para el bloqueo de penitenciarias en Perú',
        },
    ]
}
]

let comps = []
let spacing

if (window.innerWidth <= 1368) {
    spacing = 5
} else if (window.innerWidth >= 1870) {
    spacing = 15
}

for (let index = 0; index < items.length; index++) {
    comps.push(
        <Grid container id='objIn' columns={{ xs: 1, md: 9, lg: 9 }} columnSpacing={{ md: 3, lg: 3 }} rowSpacing={{ xs: 0, md: 0, lg: 0 }} direction='row'>{
            items[index].items.map((obj) =>
                <Grid container justifyContent={'center'} xs={12} md={4} lg={4}>
                    <Card sx={{ maxWidth: 300 }}>
                        <CardMedia sx={{ height: 300 }} image={obj.image} />
                        <CardContent>
                            <Typography>
                                {obj.title}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>)
        }</Grid>
    )
}

export const Index = () => {

    const nav = useNavigate()
   
         useEffect(() => {
             window.scrollTo(0, 0)
         }, [])
   
    return (
        <>

            <Grid container>

                <Grid item container direction={'row'}  >
                    <div className="video-wrapper">
                        <video autoPlay loop muted>
                            <source src="videos/Video_LandingPage.mp4" type="video/mp4" />
                        </video>
                    </div>

                    <Grid item container xs={12} md={12} lg={5} direction={'row'} rowSpacing={{ xs: 2 }} id='cont1'>
                        <Grid item id='text1Index' xs={12}>
                            Mejorando sus operaciones con tecnología
                        </Grid>
                        <Grid item container xs={12} md={6} lg={6} direction={'row'} >
                            <Grid container xs={0.4} id='imgDivIndex'>
                                <img src='images/divisorV.png' id='divV' />
                            </Grid>
                            <Grid container direction={'column'} xs rowSpacing={{ xs: 3, md: 5, lg: 3 }} id='opcionesIndex'>
                                <Grid item xs={2} id='opcionesIndex'>
                                    <Link to={'historia/operaciones'} state={'operaciones'}>
                                        Operaciones de campo
                                    </Link>
                                </Grid>
                                <Grid item xs={2} id='opcionesIndex'>
                                    <Link to={'historia/comunicaciones'} state={'comunicaciones'}>
                                        Comunicaciones
                                    </Link>
                                </Grid>
                                <Grid item xs={2} id='opcionesIndex'>
                                    <Link to={'historia/ciudadsegura'} state={'ciudadsegura'}>
                                        Ciudad segura
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={8}>
                            {/*
                            <Button variant='contained' disableElevation id='contacto' onClick={() => { nav('contacto') }}>Contáctanos </Button>
                            */}
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item container columns={{ xs: 2, md: 1, lg: 1 }} direction={{ xs: 'row', md: 'row', lg: 'row' }} id='bloque4Index'>
                    <Grid item xs md={12} id='text1Bloq4Index'>
                        20+ entidades acuden a nosotros como aliado tecnológico
                    </Grid>

                    <Grid item container xs id='text2Bloq4Index'>
                        {/*
                        <Link to='*'>
                            <Grid item container direction={'row'}>
                                <Grid item>
                                    Algunos clientes
                                </Grid>
                                <Grid item>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                         */}
                    </Grid>

                    {/*Movil*/}

                    <Grid container xs={8} id='text1Bloq4IndexCell' >
                        +20 clientes satisfechos
                    </Grid>

                    <Grid container xs={4} id='text2Bloq4IndexCell-0'  >
                        <Link to='noticias' xs={12} >
                            <Grid container xs={12} direction={'row'} >
                                <Grid container xs={8} id='textLink' >
                                    Ver más
                                </Grid >
                                <Grid container xs={4}>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                    <Grid container xs={12} id='divCell'>
                        <img src='images/divisionHorizontal.png' id='division' alt='cell' />
                    </Grid>

                    <Grid container md={12} columns={{ md: 1 }}>
                        <Grid container md={12}>
                            <Grid container id="logos-home-page">
                                <Grid item class="slider">
                                    <div class="slide-track">
                                        <div class="slide"><img class="alignnone size-full wp-image-13024" src='images/PrisonTec.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13023" src='images/minjusPeru.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13019" src='images/Atempi.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13019" src='images/PoliciaNacional.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13022" src='images/Smurfit.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13021" src='images/Parmalat.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-full wp-image-13020" src='images/Carvajal.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-full wp-image-13024" src='images/PrisonTec.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13023" src='images/minjusPeru.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13019" src='images/Atempi.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13019" src='images/PoliciaNacional.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13022" src='images/Smurfit.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-medium wp-image-13021" src='images/Parmalat.png' alt="" /></div>
                                        <div class="slide"><img class="alignnone size-full wp-image-13020" src='images/Carvajal.png' alt="" /></div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container id='bloque2' direction='row' columns={{ xs: 1, md: 3, lg: 3 }} >
                    <Grid item xs={12} md lg >
                        <div>
                            20+
                        </div>
                        <div>
                            Años de innovación
                        </div>
                    </Grid>
                    <Grid item xs={12} md lg>
                        <div>
                            33
                        </div>
                        <div>
                            Profesionales IT
                        </div>
                    </Grid>
                    <Grid item xs={12} md lg>
                        <div>
                            100+
                        </div>
                        <div>
                            Proyectos realizados
                        </div>
                    </Grid>
                </Grid>

                <Grid item container columns={{ xs: 2, md: 1, lg: 1 }} direction={{ xs: 'row', md: 'column', lg: 'column' }} id='bloque4Index'>
                    <Grid item xs id='title1Bloq4Index'>
                        Soluciones
                    </Grid>
                    {/*
                        <Grid item container xs id='text2Bloq4Index'>
                            <Link to='historia'>
                                <Grid item container direction={'row'}>
                                    <Grid item>
                                        Nuestras historias
                                    </Grid>
                                    <Grid item>
                                        <img src='images/arrow.png' />
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                    */}

                    {/*Movil*/}

                    <Grid container xs={8} id='text1Bloq4IndexCell'>
                        Soluciones
                    </Grid>

                    <Grid container xs={4} id='text2Bloq4IndexCell' >
                        <Link to='historia' xs={12}>
                            <Grid container xs={12} direction={'row'} id='text2Bloq4IndexCell-1' >
                                <Grid container xs={8} id='textLink'>
                                    Ver más
                                </Grid>
                                <Grid container xs={4}>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                    <Grid container xs={12} id='divCell'>
                        <img src='images/divisionHorizontal.png' id='division' alt='cell' />
                    </Grid>

                    <Grid container xs columns={{ xs: 1, lg: 9 }}>
                        <MiniHistorias />
                    </Grid>

                    <Grid container xs id='text2Bloq4Index'>
                        <Link to='historia'>
                            <Grid container direction={'row'}>
                                <Grid item>
                                    Más soluciones
                                </Grid>
                                <Grid item>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                </Grid>



                <Grid item container columns={{ xs: 2, md: 1, lg: 1 }} direction={{ xs: 'row', md: 'column', lg: 'column' }} id='bloque4Index'>
                    <Grid item xs id='title1Bloq4Index'>
                        Noticias
                    </Grid>

                    {/*Movil*/}

                    <Grid container xs={8} id='text1Bloq4IndexCell'>
                        Noticias
                    </Grid>

                    <Grid container xs={4} id='text2Bloq4IndexCell' >
                        <Link to='noticias' xs={12}>
                            <Grid container xs={12} direction={'row'} id='text2Bloq4IndexCell-1' >
                                <Grid container xs={8} id='textLink'>
                                    Ver más
                                </Grid>
                                <Grid container xs={4}>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                    <Grid container xs={12} id='divCell'>
                        <img src='images/divisionHorizontal.png' id='division' alt='cell' />
                    </Grid>

                    <Grid className='verslider2'>
                        {comps}
                    </Grid>

                    <div id='sliderzz' className='verslider'>
                        <Slider {...settings} >
                            {items[0].items.map((obj) =>
                                <Card sx={{ width: 800 }}>
                                    <CardMedia sx={{ height: 480 }} image={obj.image} />
                                    <CardContent sx={{ height: 85 }}>
                                        <Typography theme={cardTxtmain}>
                                            {obj.title}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                            }
                        </Slider>
                    </div>

                    <Grid item container xs id='text2Bloq4Index' >
                        <Link to='noticias'>
                            <Grid item container direction={'row'}>
                                <Grid item>
                                    Más noticias
                                </Grid>
                                <Grid item>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                </Grid>

            </Grid>

        </>
    )
}

