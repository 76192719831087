import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import Swal from 'sweetalert2'
import React from 'react'

export const NoticiasIndex = () => {


    const prision = () => {

        Swal.fire({
            html: ' <video autoPlay controls ><source src="videos/Proinversion Prisontec-720p.mp4" type="video/mp4" /></video>',
            text: '720px',
            confirmButtonText: 'Cerrar video'
        })

    }

    const huaral = () => {
        Swal.fire({
            html: ' <video autoPlay controls ><source src="videos/Noticia Inauguracion de bloqueadores en penal de Huaral-720p.mp4" type="video/mp4" /></video>',
            text: '720px',
            confirmButtonText: 'Cerrar video'
        })

    }
    const solucionBloqueo = () => {
        Swal.fire({
            html: ' <video autoPlay controls ><source src="videos/Solucion al bloqueo en penintenciarias Prisontec-720p.mp4" type="video/mp4" /></video>',
            text: '720px',
            confirmButtonText: 'Cerrar video'
        })

    }
    const grilletes = () => {
        Swal.fire({
            html: ' <video autoPlay controls ><source src="videos/Grilletes-720p.mp4" type="video/mp4" /></video>',
            text: '720px',
            confirmButtonText: 'Cerrar video'
        })

    }

    const grilletesanimado = () => {
        Swal.fire({
            html: ' <video autoPlay controls ><source src="videos/Grillete Electronico VEP Global Circuit_720.mp4" type="video/mp4" /></video>',
            text: '720px',
            confirmButtonText: 'Cerrar video'
        })

    }

    return (
        <>
            <Grid container columns={{ xs: 1, md: 2, lg: 2 }} direction='row'>
                <Grid container xs={12} md={12} id='history' direction={'row'}>
                    <Grid container xs={12} md={12}>
                        <div>
                            Noticias
                        </div>
                    </Grid>
                    <Grid container xs={12} md={12}>
                        <img src="images/divisionHorizontal.png" id='division' alt='cell' />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='row' columns={{ xs: 1, md: 9, lg: 9 }}  id='containerNot'>
                <Grid container xs={12} md={4} justifyContent={{xs:'center', md:'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ maxWidth: 400, height: 600 }}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/carcel.png'
                            title='seguridad'
                        />
                        <CardContent>
                            <Typography gutterBottom component="div" id='txtCardTitle'>
                                Seguridad tecnológica para 33 penitenciarías en Perú
                            </Typography>
                            <Typography id='txtCard' color="text.secondary">
                                El consorcio Prisontec conformado por la empresa Global Circuit SAS y Global Circuit Perú,
                                obtienen el contrato para instalar y operar el sistema de bloqueo y comunicaciones para
                                33 penitenciarías por 25 años

                            </Typography>
                            <br></br>
                            <Typography variant="body2" color="text.secondary">


                                <a onClick={prision} style={{ color: "#1A1A51" }} id="btnoptions">
                                    Más información...
                                </a>

                            </Typography>


                        </CardContent>
                    </Card>
                </Grid>
                <Grid container xs={12} md={4} justifyContent={'center'} >
                    <Card
                        sx={{ maxWidth: 400, height: 600 }}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/kokoriko.png'
                            title='kokoriko'
                        />
                        <CardContent>
                            <Typography gutterBottom id='txtCardTitle-2' component="div">
                                Mejoramiento tecnológico de la operación y seguridad de los agentes de tránsito
                                para la ciudad de Cali
                            </Typography>
                            <Typography id='txtCard-2' color="text.secondary">
                                Global Circuit ganó el concurso para el sistema de mejoramiento de la productividad,
                                seguridad y control de los agentes de tránsito de la ciudad de Cali, utilizando el rastreo satelital
                                y software de ultima generación
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container xs={12} md={4} justifyContent={'center'} >
                    <Card
                        sx={{ maxWidth: 400, height: 600 }}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/bota.png'
                            title='grillete'
                        />
                        <CardContent>
                            <Typography gutterBottom id='txtCardTitle' component="div">
                                Grilletes electrónicos
                            </Typography>
                            <Typography id='txtCard-2' color="text.secondary">
                                El consorcio Global Seguridad conformado por Global Circuit, Global Circuit Perú y otros, ganan
                                el concurso para implementar y operar el servicio de vigilancia mediante grilletes electrónicos
                            </Typography>
                            <br></br>
                            <Typography variant="body2" color="text.secondary">
                                <a onClick={grilletes} style={{ color: "#1A1A51" }} id="btnoptions">
                                    Más información...
                                </a>
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                                <a onClick={grilletesanimado} style={{ color: "#1A1A51" }} id="btnoptions">
                                    Más información...
                                </a>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid container xs={12} md={4} justifyContent={'center'}  >
                    <Card
                        sx={{ maxWidth: 400, height: 600 }}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/ministro.png'
                            title='ministro'
                        />
                        <CardContent>
                            <Typography gutterBottom id='txtCardTitle-2' component="div">
                                Ministro de justicia inaugura sistema de bloqueo en la penitenciaría de
                                Huaral en Perú
                            </Typography>
                            <Typography id='txtCard-3' color="text.secondary">
                                El consorcio PrisonTec avanza en el proceso de instalación del sistema de bloqueo y telefonía
                                segura en los penales de Cañete, Huaral e Ica
                            </Typography>
                            <br></br>
                            <Typography variant="body2" color="text.secondary">
                                <a onClick={huaral} style={{ color: "#1A1A51" }} id="btnoptions">
                                    Más información...
                                </a>

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid container xs={12} md={4} justifyContent={'center'}  >
                    <Card
                        sx={{ maxWidth: 400, height: 600 }}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/bloqueo.jpg'
                            title='bloqueo en penitenciarias'
                        />
                        <CardContent>
                            <Typography gutterBottom id='txtCardTitle' component="div">
                                Solución para el bloqueo de penitenciarias
                            </Typography>
                            <Typography id='txtCard-3' color="text.secondary">
                                Prisontec y el estado Peruano construyen la
                                solución para bloquear efectivamente los celulares dentro de las prisiones con muy poca afectación a la población vecina
                            </Typography>
                            <br></br>
                            <Typography variant="body2" color="text.secondary">
                                <a onClick={solucionBloqueo} style={{ color: "#1A1A51" }} id="btnoptions">
                                    Más información...
                                </a>

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>


        </>
    )
}
