import { Cancel, CancelOutlined, Close } from '@mui/icons-material'
import { Box, Button, ClickAwayListener, Grid, Grow, Menu, MenuItem, MenuList, Modal, Paper, Popper, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

export const NavBar = () => {

    const nav = useNavigate()
    const loc = useLocation()


    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleNavi = () => {
        setOpen((prevOpen) => !prevOpen);
        nav('quienesomos')
    };

    const handleClose = (e) => {
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }

        setOpen(false);

        switch (e.target.id) {
            case 'quienesomos':
                nav(e.target.id)
                break;

            case 'quehacemos':
                nav(e.target.id)
                break;

            case 'noticias':
                nav(e.target.id)
                break;

            case 'fundacion':
                nav(e.target.id)
                break;

            case 'fullvision':
                nav('quehacemos', { state: { message: "navbar" } })
                break;
            default:
                break;
        }



    };


    {/*Movil*/ }
    const [open2, setOpen2] = useState(false);
    const handleOpen = () => setOpen2(true);


    const handleClose2 = (e) => {
        setOpen2(false);

        switch (e.target.id) {
            case 'logoCell':
                nav('')
                break;

            case 'quienesomos':
                nav(e.target.id)
                break;

            case 'quehacemos':
                nav(e.target.id)
                break;

            case 'noticias':
                nav(e.target.id)
                break;

            case 'historias':
                nav('historia')
                break;

            case 'operaciones':
                nav('historia/operaciones')
                break;

            case 'ciudadsegura':
                nav('historia/ciudadsegura')
                break;
            case 'comunicaciones':
                nav('historia/comunicaciones')
                break;

            case 'fundacion':
                nav(e.target.id)
                break;

            case 'trabaja':
                nav(e.target.id)
                break;

            case 'contacto':
                nav(e.target.id)
                break;


            default:
                break;
        }


    }
    {/*End movil*/ }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <>

            <Box sx={{ flexGrow: 1 }} className='pad' id='todo'>
                <Box>
                    <div>
                        <img src='images/LogoGlobal.png' id='logo' onClick={(e) => { nav('') }} />
                    </div>
                </Box>

                <Box gridColumn='spam 12' className='pad-letters' display='flex' id='navL'>


                    <Link to={'quehacemos'}>
                        <div id='linkNav'>
                            ¿Qué hacemos?
                        </div>
                    </Link>

                    <Link to={'historia'}>
                        <div id='linkNav'>
                            Nuestras Historias
                        </div>
                    </Link>



                    <div>
                        <Link to={'quienesomos'}>
                            <div
                                ref={anchorRef}
                                aria-controls={open ? 'composition-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onMouseOver={handleToggle}
                                onClick={handleNavi}
                                id='quienesomos'
                            >
                                Nosotros
                            </div>
                        </Link>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            id='pop'
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                            >
                                                <MenuItem onClick={handleClose} id='fullvision'>Tecnología</MenuItem>
                                                <MenuItem onClick={handleClose} id='noticias'>Noticias</MenuItem>
                                                <MenuItem onClick={handleClose} id='fundacion'>Responsabilidad Social</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>

                    <Link to={'trabaja'}>
                        <div id='linkNav'>
                            Trabaja con nosotros
                        </div>
                    </Link>

                    <Button variant='contained' disableElevation onClick={() => { nav('contacto') }}>Contacto</Button>

                </Box>
            </Box>

            {/*Nav Movil*/}

            <Grid container columns={{ xs: 2, md: 2 }} className='pad2' columnSpacing={{ xs: 5 }}   >

                <Grid container item xs={6.6} md={0}>
                    <img src='images/LogoGlobal.png' id='logo' onClick={(e) => { nav('') }} />
                </Grid>

                <Grid container item xs={1} md={2} id='hamMen' onClick={handleOpen} >
                    <MenuIcon sx={{ fontSize: 60 }} />
                </Grid>

            </Grid>

            {/*Menu Movil*/}

            <Modal
                open={open2}
                aria-labelledby="modal-modal-title"
                onClose={handleClose2}
                aria-describedby="modal-modal-description"
                id='menu'
            >
                <Grid container sx={{ backgroundColor: "#257AF4", height: '100%' }} id='cont'>

                    <Grid container className='padCell'>
                        <Grid container columns={{ xs: 2 }}>
                            <Grid item id='/' xs onClick={handleClose2}>
                                <img src='images/LogoBlancoGlobal.png' id='logoCell' />
                            </Grid>
                            <Grid container justifyContent={'right'} xs onClick={handleClose2}>
                                <Close />
                            </Grid>
                        </Grid>
                        <Grid container columns={{ xs: 1 }} rowSpacing={{ xs: 1 }} direction={'column'} id='rowMenu'>
                            <Grid item id='quienesomos' onClick={handleClose2} className='col1Menu'>
                                ¿Quiénes somos?
                            </Grid>
                            <Grid item id='quehacemos' onClick={handleClose2} className='col2Menu'>
                                ¿Qué hacemos?
                            </Grid>
                            <Grid item id='noticias' onClick={handleClose2} className='col2Menu'>
                                Noticias
                            </Grid>
                        </Grid>

                        <Grid container columns={{ xs: 1 }} direction={'column'} id='rowMenu'>
                            <Grid item id='historias' onClick={handleClose2} className='col1Menu'>
                                Nuestras historias
                            </Grid>
                            <Grid item id='operaciones' onClick={handleClose2} className='col2Menu'>
                                Operaciones de campo
                            </Grid>
                            <Grid item id='comunicaciones' onClick={handleClose2} className='col2Menu'>
                                comunicaciones
                            </Grid>
                            <Grid item id='ciudadsegura' onClick={handleClose2} className='col2Menu'>
                                Seguridad
                            </Grid>
                        </Grid>

                        <Grid container columns={{ xs: 1 }} direction={'column'} id='rowMenu'>
                            <Grid item id='fundacion' onClick={handleClose2} className='col1Menu'>
                                Responsabilidad social
                            </Grid>
                            <Grid item id='fundacion' onClick={handleClose2} className='col2Menu'>
                                Libertad en mi Prisión
                            </Grid>
                        </Grid>

                        <Grid container columns={{ xs: 1 }} direction={'column'} id='rowMenu'>
                            <Grid item id='trabaja' onClick={handleClose2} className='col1Menu'>
                                Trabaja con nosotros
                            </Grid>
                            <Grid item id='contacto' onClick={handleClose2} className='col2Menu'>
                                Contacto
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Button variant='contained' disableElevation size={'large'}  >
                                Contáctanos
                                <ArrowForwardIcon />
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Modal>


        </>
    )
}
