import { Grid } from '@mui/material'
import React, { useEffect } from 'react'

export const Citas = () => {


    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    {/*Agregar identación*/ }

    {/*Todos mismo tamaño de letra*/ }

    {/*Debajo de resultados poner un separador*/ }

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Agendamiento amable y ágil de citas
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Operaciones de campo
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container id='divAntena'>
                <img src='images/Citas.jpg' id='antena' />
            </Grid>

            <Grid container columns={{ md: 3 }} direction='row' id='row1Text'>
                <Grid container md={7} direction='column'>
                    <Grid item md={2} id='titleRow1'>
                        Cliente
                    </Grid>
                    <Grid item md={4} id='subtitleRow1'>
                        Setravial, Hospital piloto Jamundí
                    </Grid>
                </Grid>

                <Grid container md={5} id='textRow1-t'>
                    Sistema Agenda tu cita® que permite a los clientes agendar de manera ágil y amable sus citas las 7x24 dando
                    relevancia a las preferencias del cliente ya sea por teléfono, por Web,  WhatsApp usando automatización solo
                    donde es relevante y mejorando la experiencia del usuario.
                </Grid>
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row2Text'>

                <Grid container md={6}>
                    <Grid item id='titleRow3-c'>
                        Contexto 
                    </Grid>

                    <Grid item md={9.6} id='textRow3-t' textAlign={'justify'}>
                        La excelencia en la atención al usuario empieza cuando realiza el primer contacto con la empresa y es cuando se hace la cita,
                        el objetivo es apoyarse en la tecnología para mejorar la experiencia y dar desde el comienzo un excelente servicio y eficiencia
                        en la gestión de agendamiento.
                    </Grid>

                </Grid>

                <Grid container md={6}>
                    <Grid item md={4} id='titleRow3' >
                        Solución
                    </Grid>
                    
                    <Grid item id='textRow3'>
                        Se instaló el sistema Agenda tu cita que combina teléfono, web y whatsApp para agendar la cita de acuerdo a la preferencia del
                        cliente y que da prioridad a la atención con personal humano, y agiliza y mejora la atención con el uso de herramientas de
                        Inteligencia Artificial tales como reconocimiento de voz, respuestas por voz, Bots.
                    </Grid>

                    <Grid item id='textRow3'>
                        Las citas también se confirman automáticamente de la manera como el usuario lo prefiere.
                    </Grid>

                </Grid>

            </Grid>

            <Grid container columns={{ md: 1 }} id='row3Text' rowSpacing={{ md: 2 }}>
                <Grid item id='titleRow3'>
                    Resultados
                </Grid>
                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
                <Grid item id='textRow3'>
                    Se mejoró radicalmente la atención de los usuarios logrando ampliar el horario de atención a 24 horas con una baja inversión de recursos,
                    incrementando el número de clientes atendidos en el mes.
                </Grid>
            </Grid>
        </>
    )
}
