import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import Swal from 'sweetalert2'
//import { Link } from 'react-router-dom'
//import Pdf from '/src/documents/CiudadSeguraGlobal.pdf'
//import PDFViewer from 'pdf-viewer-reactjs'



export const CiudadSegura = () => {

    const nav = useNavigate()
    const loc = useLocation()

    let height

    useEffect(() => {

        if (loc.pathname === '/historia') {
            height = 640
        } else if (loc.pathname === '/historia/ciudadsegura') {
            height = 710
        }

    }, [loc.pathname])

    const modalpdf = () => {

        Swal.fire({
            title: '',
            html:
                ' <div class="modal">' +
                '<embed class="embed-responsive-item" src="/documents/CiudadSeguraGlobal.pdf" type="application/pdf" width="800px" height="500px" id="pdf" frameborder="0"  ></embed>' +
                ' </div>',
            position: 'center',
            scrollbarPadding: 'none',
            confirmButtonText: 'Cerrar PDF'
        })
    }

    const modalpdf2 = () => {

        Swal.fire({
            title: '',
            html:
                ' <div class="modal">' +
                '<embed class="embed-responsive-item" src="/documents/ProteccionElectronicaVictimasViolenciaDomestica.pdf" type="application/pdf" width="800px" height="500px" id="pdf" frameborder="0"  ></embed>' +
                ' </div>',
            position: 'center',
            scrollbarPadding: 'none',
            confirmButtonText: 'Cerrar PDF'
        })
    }

    return (
        <>
            <Grid id='titulosHisto'> Ciudad Segura </Grid>

            <Grid container direction='row' columns={{ xs: 1, sm: 9, md: 9, lg: 9 }} id='subcontainerHisto'>
                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm: 'normal', md: 'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ maxWidth: 300, height: height }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/antena.png'

                        />
                        <CardContent id='firstCardCS' className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Bloqueo de señales inalámbricas en prisiones
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Ciudad segura
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Sistema electrónico que impide el uso de celulares dentro
                                de las prisiones, bloqueando las señales inalámbricas.
                            </Typography>
                        </CardContent>
                        <CardActions className={'portButCl'}>
                            <Button onClick={() => { nav('/historia/detalleHis') }}>Más información</Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm: 'normal', md: 'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ maxWidth: 300, height: height }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/kokoriko.png'

                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Control de patrullas y agentes
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Ciudad segura
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Permite a las autoridades tener visibilidad en tiempo real de dónde
                                está cada uno de los vehículos, patrullas, motocicletas y agentes con el
                                fin de dar mejor cubrimiento de la zona vigilada de manera eficiente. Con la aplicación
                                cada agente puede capturar y consultar información de manera ágil para facilitar
                                su trabajo.

                                Con las estadísticas del sistema se logra optimizar los recursos humanos
                                y racionalizar el uso de los vehículos ahorrando en combustible, repuestos y
                                talleres.

                                La analítica de imágenes automática con inteligencia artificial
                                apoya el trabajo de campo de los agentes para identificar infractores.

                            </Typography>

                        </CardContent>

                        <CardActions className={'portButCl'}>
                            <Button onClick={modalpdf} style={{ color: "#1976d2" }} id="btnoptions">Más información</Button>
                        </CardActions>

                    </Card>

                </Grid>

                <Grid container xs={12} sm={6} md={4} justifyContent={{ xs: 'center', sm: 'normal', md: 'normal' }} paddingBottom={5}>
                    <Card
                        sx={{ maxWidth: 300, height: height }} className={'portCardCl'}
                    >
                        <CardMedia
                            sx={{ height: 200 }}
                            image='images/relojCS.jpg'

                        />
                        <CardContent className={'portBodyCl'}>
                            <Typography gutterBottom variant="h5" component="div">
                                Vigilancia electrónica personal
                            </Typography>
                            <Typography component={'div'} variant='subtitle1'>
                                Ciudad segura
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <Typography variant="body2" color="text.secondary" >
                                    <Box sx={{ fontWeight: 'bold', m: 1 }}>
                                        •	Protección para líderes sociales y personalidades
                                    </Box>
                                </Typography>
                                Primer nivel de protección a líderes sociales, autoridades regionales y otro tipo
                                de personas en riesgo que no tienen la posibilidad de tener escoltas.
                                El protegido lleva un reloj que muestra la localización en todo momento,
                                tiene un botón de SOS y permite escucha silenciosa de lo que está sucediendo
                                en reacción a un SOS. La vigilancia se realiza en un centro de monitoreo desde
                                donde se coordina la reacción para la protección.
                                <Typography variant="body2" color="text.secondary" >
                                    <Box sx={{ fontWeight: 'bold', m: 1 }}>
                                        •	Protección electrónica para víctimas de violencia doméstica
                                    </Box>
                                </Typography>
                                Este Sistema es utilizado en varios países para la lucha contra el flagelo de
                                la violencia doméstica. El agresor y la víctima llevan un dispositivo electrónico
                                que mantiene informada su localización a un centro especializado de monitoreo.

                                <Typography variant="body2" color="text.secondary" >
                                    <Box sx={{ fontWeight: 'bold', m: 1 }}>
                                        •	Grillete Electrónico
                                    </Box>
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Permite deshacinar las cárceles evitando que personas con tipos de delitos
                                    no peligrosos ingresen a ellas y a cambio pagan la condena en su domicilio
                                    o con movilidad restringida a ciertas zonas, la vigilancia la hace un centro
                                    de monitoreo y en caso de infracción interviene la policía.
                                </Typography>

                            </Typography>

                        </CardContent>

                        <CardActions className={'portButCl'}>
                            <Button onClick={modalpdf2} style={{ color: "#1976d2" }} id="btnoptions">Más información</Button>
                        </CardActions>

                    </Card>
                </Grid>

            </Grid >
        </>
    )
}






