import { Window } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography, createTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Slider from 'react-slick';
import DataContext from '../Context/DataContext';



const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    pauseOnFocus: true

}

const cardTxtmain = createTheme({
    typography: {
        fontSize: 18,
        fontWeightMedium: 500,
    },
})

const items = [{
    items: [
        {
            image: 'images/carcel.png',
            title: 'Seguridad tecnológica para 33 penitenciarias en Perú',
        }, {
            image: 'images/kokoriko.png',
            title: 'Mejoramiento tecnológico de la operación y seguridad de los agentes de tránsito para Cali',
        }, {
            image: 'images/ministro.png',
            title: 'Ministro de justicia inaugura sistema de bloque en la penitenciaria de Huaral Perú',
        }
    ]
},
{
    items: [
        {
            image: 'images/bota.png',
            title: 'Global Circuit, Global Circuit Perú y otros, ganan el concurso para implementar y operar el servicio de vigilancia mediante grilletes electrónicos',
        }, {
            image: 'images/bloqueo.jpg',
            title: 'Solución al bloqueo en penitenciarias en Perú',
        },
    ]
}
]

let comps = []


for (let index = 0; index < items.length; index++) {
    comps.push(
        <Grid container id='objIn' columns={{ xs: 1, md: 9, lg: 9 }} columnSpacing={{ md: 3, lg: 3 }} rowSpacing={{ xs: 0, md: 0, lg: 0 }} direction='row'>{
            items[index].items.map((obj) =>
                <Grid container justifyContent={'center'} xs={12} md={4} lg={4}>
                    <Card sx={{ maxWidth: 300 }}>
                        <CardMedia sx={{ height: 200 }} image={obj.image} />
                        <CardContent>
                            <Typography>
                                {obj.title}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>)
        }</Grid>
    )
}




export const QuienesSomos = () => {

    const [spacing, setSpacing] = useState(0)
    const [spacing2, setSpacing2] = useState(0)

    const nav = useNavigate()

    /*useEffect(() => {
           window.scrollTo(0, 0)
       })
       
           if (window.innerWidth <= 1368) {
               setSpacing(13)
               setSpacing2(5)
           } else if (window.innerWidth >= 1870) {
               setSpacing(20)
               setSpacing2(10)
           }
      */

    return (

        <Grid container id='index' direction={'row'}>
            <Grid container item>
                <img src="images/imgQS.jpg" id='abogada' />
            </Grid>

            <Grid item id='text1'>
                Buscamos agilizar procesos a través de la tecnología,
                <br />
                mejorando la productividad de nuestros clientes
            </Grid>

            <Grid item container id='bloque2' direction={{ xs: 'column', md: 'row', lg: 'row' }} columns={{ xs: 1, md: 3, lg: 3 }} spacing={{ xs: 3, md: 19 }}>
                <Grid item>
                    <div>
                        20+
                    </div>
                    <div>
                        Años de innovación
                    </div>
                </Grid>
                <Grid item >
                    <div>
                        33
                    </div>
                    <div>
                        Profesionales IT
                    </div>
                </Grid>
                <Grid item>
                    <div>
                        100+
                    </div>
                    <div>
                        Proyectos realizados
                    </div>
                </Grid>
            </Grid>

            <Grid item container direction='row' columns={{ xs: 2, md: 2, lg: 2 }} id='bloque3' spacing={{ md: 8 }} >
                <Grid item container direction={'column'} xs={12} md={3} lg={3}  >
                    <Grid item id='first'>
                        ¿Qué hacemos?
                    </Grid>
                    <Grid item id='second'>
                        Nos enfocamos en casos individuales para crear soluciones personalizadas
                        para cada uno de nuestros clientes
                    </Grid>

                    <Grid item container xs id='link'>
                        <Link to='/quehacemos'>
                            <Grid item container direction={'row'}>
                                <Grid item id='full'>
                                    Cómo lo hacemos
                                </Grid>
                                <Grid item>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>

                </Grid>

                {/*Movil*/}
                
                <Grid container xs={7} id='firstCell'  >
                    ¿Qué hacemos?
                </Grid>

                <Grid container xs={4.6} id='text2Bloq4IndexCell' className='vermovil'>
                    <Link to='/quehacemos'>
                        <Grid container direction={'row'} xs={12} id='text2Bloq4IndexCell-2'>
                            <Grid item xs id='textLink'>
                                Ver más
                            </Grid>
                            <img src='images/arrow.png' />
                        </Grid>
                    </Link>
                </Grid>
               
                <Grid container xs={12} id='divCell'>
                    <img src='images/divisionHorizontal.png' id='division' alt='cell' />
                </Grid>
                
                {/*End Movil*/}

                <Grid item container xs={12} md={9} lg={9}>
                    <img src='images/trabajador.png' id='trabajador'></img>
                </Grid>

                {/*Movil*/}
                <Grid item id='secondCell'>
                    Nos enfocamos en casos individuales para crear soluciones personalizadas
                    para cada uno de nuestros clientes
                </Grid>
                {/*End Movil*/}

            </Grid>

            <Grid item container direction='row' columns={{ xs: 1, md: 2, lg: 2 }} id='bloque3' spacing={{ md: 8 }} >
                <Grid item container direction={'column'} xs={12} md={3} lg={3}>
                    <Grid item id='first'>
                        Tecnología: Fullvision
                    </Grid>
                    <Grid item id='second'>
                        Creemos que la innovación tecnológica es la piedra fundamental para el desarrollo
                        de América Latina
                    </Grid>

                    {/*Movil*/}
                    <Grid item id='firstCell'>
                        Tecnología: Fullvision
                    </Grid>
                    {/*End Movil*/}

                    <Grid item container xs id='link'>
                        <Link to={'/quehacemos'} state={'navbar'}>
                            <Grid item container direction={'row'}>
                                <Grid item id='full'>
                                    Conoce Fullvision
                                </Grid>
                                <Grid item>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={9} lg={9}>
                    <img src='images/fullvision.png' id='fullvision'></img>
                </Grid>

                {/*Movil*/}
                <Grid item id='secondCell'>
                    Creemos que la innovación tecnológica es la piedra fundamental para el desarrollo
                    de América Latina
                </Grid>
                {/*End Movil*/}

            </Grid>

            <Grid item container columns={{ xs: 1 }} direction='column' id='bloque4'>
                <Grid item xs id='text1Bloq4' style={{margin:'5px'}}>
                    Lo que se dice de nosotros
                </Grid>
                <Grid item container xs id='text2Bloq4'>
                    <Link to='/noticias'>
                        <Grid item container direction={'row'}>
                            <Grid item style={{fontSize:'20px'}} >
                                Noticias
                            </Grid>
                            <Grid item style={{marginLeft:'6px'}}  >
                                <img src='images/arrow.png' />
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>
            </Grid>

            {/*
                    carousel
                    */}
            <Grid className='verslider2'>
                {comps}
            </Grid>

            <div id='sliderzz' className='verslider'>
                <Slider {...settings} >
                    {items[0].items.map((obj) =>
                        <Card sx={{ width: 800 }}>
                            <CardMedia sx={{ height: 400 }} image={obj.image} />
                            <CardContent sx={{ height: 85 }}>
                                <Typography theme={cardTxtmain}>
                                    {obj.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    )
                    }
                </Slider>
            </div>




            <Grid item container direction='row' columns={{ xs: 1, md: 2, lg: 2 }} id='bloque3' spacing={{ md: 8 }} >
                <Grid item container direction={'column'} xs={12} md={3} lg={3}>
                    <Grid item id='first'>
                        Responsabilidad Social
                    </Grid>
                    <Grid item id='second'>
                        La fundación Libertad en mi Prisión busca impactar de manera positiva en
                        la vida de las personas privadas de la libertad
                    </Grid>

                    {/*Movil*/}
                    <Grid item id='firstCell'>
                        Responsabilidad Social
                    </Grid>
                    {/*End Movil*/}

                    <Grid item container xs id='link'>
                        <Link to='/fundacion'>
                            <Grid item container direction={'row'}>
                                <Grid item id='full'>
                                    Conocer la fundación
                                </Grid>
                                <Grid item>
                                    <img src='images/arrow.png' />
                                </Grid>
                            </Grid>
                        </Link>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={9} lg={9}>
                    <img src='images/peru.png' id='peru'></img>
                </Grid>

                {/*Movil*/}
                <Grid item id='secondCell'>
                    La fundación Libertad en mi Prisión busca impactar de manera positiva en
                    la vida de las personas privadas de la libertad
                </Grid>
                {/*End Movil*/}

            </Grid>

        </Grid>

    )
}
