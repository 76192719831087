import { Grid } from '@mui/material'
import React, { useEffect } from 'react'

export const Telefonia = () => {

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])
  
    {/*Agregar identación*/ }

    {/*Todos mismo tamaño de letra*/ }

    {/*Debajo de resultados poner un separador*/ }

    return (
        <>
            <Grid container columns={{ md: 2 }} direction='row'>
                <Grid item md={1} id='titleNet1' textAlign={'center'}>
                    Telefonía segura para Penitenciarias
                </Grid>

                <Grid item md={1} id='subtitleNet1' textAlign={'right'}>
                    Comunicaciones
                </Grid>

                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
            </Grid>

            <Grid container id='divAntena'>
                <img src='images/penal.png' id='antena' />
            </Grid>

            <Grid container columns={{ md: 3 }} direction='row' id='row1Text'>
                <Grid container md={7} direction='column'>
                    <Grid item md={2} id='titleRow1'>
                        Cliente
                    </Grid>
                    <Grid item md={4} id='subtitleRow1'>
                        Ministerio de Justicia de Perú
                    </Grid>
                </Grid>

                <Grid container md={5} id='textRow1-t'>
                    Sistema de teléfonos y videoteléfonos especiales para penitenciarias que permiten establecer comunicaciones seguras para la óptima comunicación de
                    los internos. Este sistema permite a las autoridades
                    mantener el control de las comunicaciones y mediante el Sistema de Inteligencia Penitenciario (SIP) realizar investigaciones efectivas de delitos
                    cometidos desde las prisiones.
                </Grid>
            </Grid>

            <Grid container columns={{ md: 2 }} direction='row' id='row2Text'>

                <Grid container md={6}>
                    <Grid item id='titleRow3-c'>
                        Contexto
                    </Grid>
                    <Grid item md={9.6} id='textRow3-t' >
                        Los internos necesitan tener comunicaciones de buena calidad y económicas. Las autoridades necesitan controlar las comunicaciones y tener una herramienta
                        que permita realizar investigaciones criminales.
                    </Grid>
                </Grid>

                <Grid container md={6}>
                    <Grid item id='titleRow3' >
                        Solución
                    </Grid>

                    <Grid item id='textRow3-t'>
                        Global Circuit diseñó un sistema telefónico de alta calidad para que la comunicación de los internos con sus familiares y
                        amigos se haga de manera segura y controlada. Este sistema se acompaña de un software de minería de datos y análisis de voz para realizar las investigaciones criminales.
                    </Grid>
                </Grid>

            </Grid>

            <Grid container columns={{ md: 1 }} id='row3Text' rowSpacing={{ md: 2 }}>
                <Grid item id='titleRow3'>
                    Resultados
                </Grid>
                <Grid item md={2}>
                    <img src='images/divisionHorizontal.png' id='division' />
                </Grid>
                <Grid item id='textRow3'>
                    Una población muy satisfecha por el servicio telefónico, realizan mas de 5 millones de llamadas al mes, recibiendo servicios
                    adicionales a través de los teléfonos y video teléfonos. Las autoridades ahora cuentan con un poderosos sistema que apoya la
                    información e investigación criminal.
                </Grid>
            </Grid>
        </>
    )
}
