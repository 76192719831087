import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import DataContext from '../Context/DataContext'
import { RouteMiniHis } from '../routes/routeMiniHis'
import { HistoriasIndex } from './miniComponents/historiasIndex'

export const Historias = () => {
    /*El error del nav era el spacing en 50 XD*/
    const { todo, setCss } = useContext(DataContext)

    const loc = useLocation()


    useEffect(() => {

        setCss(loc.pathname, window.innerWidth)

    }, [])

    return (
        <>
            <Grid container justifyContent={'center'} >

                <HistoriasIndex />

                <RouteMiniHis />

            </Grid>


        </>
    )
}
